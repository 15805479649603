import axios from 'axios'
import { config } from 'process'
import { Config } from '~/config/config'
import { HttpClient, createFetch } from './htttp'
import { TypesenseClient } from './typesense'

export class CommonService {
  public static async homePage(limit: number, page: number) {
    return HttpClient.get(`/homePage?limit=${limit}&page=${page}`)
  }
  public static async Serialnumber(data: any) {
    return HttpClient.get(
      `/crm/apx/invoicetxn?serialnumber=${data.serialnumber}`
    )
  }
  public static async homePagination(
    session: number,
    limit: number,
    page: number
  ) {
    return HttpClient.get(
      `/home?session=${session}&limit=${limit}&page=${page}`
    )
  }
  public static async MobilehomePage(mode?: any) {
    let config: any = {
      baseURL: Config.BASE_URL,
    }
    if (!!mode) {
      config['headers'] = {
        mode,
      }
    }
    return HttpClient.get('/home', config)
  }
  public static async MobilehomePageSession(
    currentOffset: any,
    limit: any,
    mode?: any
  ) {
    let config: any = {
      baseURL: Config.BASE_URL,
    }
    if (!!mode) {
      config['headers'] = {
        mode,
      }
    }
    return HttpClient.get(
      `/api/base/home?session=${currentOffset}&limit=${limit}&page=1`,
      config
    )
  }
  public static async cache() {
    return HttpClient.delete('/api/base/cache')
  }

  public static async homePageSearch(search: string) {
    return HttpClient.get(`/api/base/autocompleteSearch?search=${search}`)
  }

  public static async homePageNavBar() {
    return HttpClient.get('/api/base/shopbycategories')
  }

  public static async ServiceCenter() {
    return HttpClient.get('/api/base/getServiceCenter')
  }

  public static async CrmServiceCenter() {
    const header = {
      token: Config.CRM_TOKEN,
    }
    const data = createFetch(
      `/crm/service-center/servicecenter/get/public`,
      'GET',
      header
    )
    return data
  }
  public static async Crmfeedback(data: {
    name: string
    mobile_number: number
    occupation: string
    imei_number: any
    comment: string
  }) {
    return HttpClient.post(
      `/crm/service-center/feedback
    `,
      data,
      {
        headers: {
          Authorization:
            'App eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjJmYTg4Y2I1LWZlMTktNGM2OS',
        },
      }
    )
  }
  public static async CrmPreRegister(data: {
    preregid: number
    name: string
    email: string
    mobile_number: number
    pincode: number
    state: string
    city: string
    area: string
    model: string
    device: number
  }) {
    return HttpClient.post(
      `/crm/service-center/preregister
    `,
      data,
      {
        headers: { Authorization: 'ziIsInR5cCI6YTg4Y2I1LWlMktNGM2OS' },
      }
    )
  }
  public static async emicalculator(data: {
    products: {
      product_id: number
      item_code: string
    }[]
  }) {
    return HttpClient.post('/api/base/emicalculator', data)
  }

  public static async validation(data: { mobile_no: string; email: string }) {
    return HttpClient.post('/api/base/validation', data)
  }

  public static async gst(gstID: string) {
    return HttpClient.get(`/crm/gst/${gstID}`)
  }

  public static async getState(param: string) {
    return HttpClient.get(`/api/base/getShowroom${param}`)
  }

  public static async reviews() {
    return HttpClient.get('api/review/1')
  }

  public static async FooterData() {
    return HttpClient.get('/api/base/footer')
  }

  public static async ContactData(data: {
    fullname: string
    email: string
    enquiry: string
  }) {
    return HttpClient.post('/crm/contactus', data)
  }
  public static async checkSlugType(slug: string) {
    return HttpClient.get(`/api/base/sitemap/checkSlug/${slug}`)
  }
  public static async getmodal(params: string) {
    return HttpClient.get(`/api/base/preregister/getPreRegister/${params}`, {
      headers: { 'access-token': 'BLLxMxgxZkGHEBh4oHAsnA' },
    })
  }

  public static async addpreregisterdata(data: {
    preregid: number
    name: string
    email: string
    mobile_number: number
    pincode: number
    state: string
    city: string
    area: string
    model: string
    device: number
  }) {
    return HttpClient.post(`/api/base/preregister/addPreRegister`, data, {
      headers: { 'access-token': 'RvvTRqFkUJ6MWML4EU4jXg' },
    })
  }

  public static async checkSlugHasProduct(slug: string) {
    return TypesenseClient({
      searches: [
        {
          collection: 'productsnew',
          q: '*',
          query_by: 'name,categories,item_code,sku',
          query_by_weights: '4,3,2,1',
          sort_by: `review_count:desc`,
          page: 1,
          per_page: 1,
          filter_by: `slug:=[\`${slug}\`]`,
        },
      ],
    })
  }

  public static async saveSearchKey(key: string) {
    return HttpClient.post('/crm/keyword', { keyword: key })
  }
}
