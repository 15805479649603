import { FC, useRef, useState } from 'react'
import { Accordion } from '~/components/ui/accordion/accordion'
import style from './mobilemenu.module.scss'
import { SubChildMobileMenu } from './subchildmobile-title'
import { MobileMenuProps } from './mobilemenu.interface'
import Link from 'next/link'
import { Plus } from '~/components/ui/icons/plus'
import { Minus } from '~/components/ui/icons/minus'
import Img from '~/components/ui/img/img'
import { useMounted } from '~/util/hooks'
// import dynamic from 'next/dynamic'
// const Img:any = dynamic(() => import('next/image'))
/**
 * this is our custom mobile menu component
 * @returns {JSX.Element} this will return a mobile menu element
 */
export const MobileMenu: FC<MobileMenuProps> = (props: any): JSX.Element => {
  const [active, setActive] = useState(0)
  const [modifiedData, setModifiedData] = useState([] as any)
  const parentRef = useRef(null)
  useMounted(
    parentRef,
    () => {
      const modified = props.data.map((element: any) => [
        element.name,
        element.children,
        element?.image?.url,
        element.category_id,
        element.slug,
        element.colorCode,
        element.isHighlightable,
        element?.linkParams?.params ? element?.linkParams?.params : [],
      ])
      setModifiedData(modified)
    },
    [props.data]
  )
  useMounted(
    parentRef,
    () => {
      toggleAccordion(props.selectedIconData)
    },
    [props.selectedIconData]
  )

  const toggleAccordion = (index: number) => {
    setActive(active === index ? -1 : index)
  }
  const linkQuery = (path) => {
    if (!!path?.pageCode && !!path?.pageType) {
      return {
        pathname: `/[slug]/page`,
        query: {
          type: path?.pageType?.toLowerCase(),
          slug: path?.pageCode,
        },
      }
    } else {
      return '/'
    }
  }
  const headerLink = (name: string, data: any) => {
    let objData: any = data?.find((e) => e.isDefault == true)
    if (objData?.isClassic) {
      switch (objData?.linkType.toUpperCase()) {
        case 'EXTERNALLINK':
          return objData?.externalUrl
        case 'PAGE':
          switch (objData?.pageType?.toUpperCase()) {
            case 'GENERAL':
              return linkQuery(objData)
            case 'CATEGORY':
              return linkQuery(objData)
            case 'BRAND':
              return linkQuery(objData)
            case 'OFFER':
              return linkQuery(objData)
          }
        default:
          return '/'
      }
    } else {
      return {
        pathname: `/s`,
        query: {
          categories: `categories:=[\`${name}\`]`,
          // stock_status: `stock_status:=[\`In Stock\`]`,
        },
      }
    }
  }
  return (
    <div
      ref={parentRef}
      className={`${props.show ? style.open_side_bar : style.side_drawer}`}
    >
      <h3 className={style.cat_heading}>Categories</h3>
      <Accordion
        variant="SINGLE"
        noIndent={true}
        selected={active}
        customHeaders={true}
      >
        {modifiedData.map((ele: any, index: number) => [
          <span key={index}>
            {ele[1].length ? (
              <div
                className="flex justify-between items-center w-full p-4 border-gray-300 border-b text-sm "
                key={`item-${index}`}
              >
                <Link
                  href={headerLink(ele[0], ele[7])}
                  key={index}
                  onClick={() => {
                    props.close()
                  }}
                >
                  <span
                    className={`flex items-center truncate ${
                      active === index ? style.bluredimg : style.grayimg
                    }`}
                  >
                    {ele[2] && (
                      <img
                        src={ele[2]}
                        className={`${style.menu_title__img} `}
                        alt={ele[2]}
                        //loader={() => ele[2]}
                        //blurDataURL={ele[2]}
                        //placeholder="blur"
                      />
                    )}
                    <span
                      className="block truncate ml-3"
                      style={{ color: `${ele[6] ? `${ele[5]}` : '#000'}` }}
                    >
                      {' '}
                      {ele[0]}
                    </span>
                  </span>
                </Link>
                <button onClick={() => toggleAccordion(index)}>
                  {active === index ? <Minus /> : <Plus />}
                </button>
              </div>
            ) : (
              <Link
                href={headerLink(ele[0], ele[7])}
                key={index}
                onClick={() => {
                  props.close()
                }}
                className={`flex w-full p-4 items-center text-sm text-gray-800 border-gray-300 border-b ${style.grayimg}`}
              >
                {/* <Img
                    className={`${style.menu_title__img} `}
                    src={ele[2]}
                    alt={ele[0]}
                    width={19}
                    height={19}
                    loader={() => ele[2]}
                    layout="responsive"
                  /> */}
                {/* <Img
                    src={ele[2]}
                    className={`${style.menu_title__img} `}
                    alt=""
                    width={19}
                    height={19}
                  /> */}
                {ele[2] && (
                  <img
                    className={`${style.menu_title__img} `}
                    src={ele[2]}
                    alt={ele[2]}
                    //loader={() => ele[2]}
                    //blurDataURL={ele[2]}
                    //placeholder="blur"
                    width={19}
                    height={19}
                  />
                )}
                <span
                  className="block truncate ml-3"
                  style={{ color: `${ele[6] ? `${ele[5]}` : '#000'}` }}
                >
                  {ele[0]}{' '}
                </span>
              </Link>
            )}
          </span>,

          ele[1].length && (
            <span key={index}>
              <SubChildMobileMenu
                data={ele[1]}
                close={props.close}
                parent={ele[0]}
              />
            </span>
          ),
        ])}
      </Accordion>
    </div>
  )
}
