import axios from 'axios'
import { Config } from '~/config/config'
import Cookies from 'js-cookie'
import { HttpClient, createFetch } from './htttp'

export class AddressService {
  public static async getManyAddress() {
    let setToken: any = Config.TOKEN_NAME
    const token = Cookies.getJSON(setToken)
    const header = {
      Authorization: `Bearer ${String(token).replace('%22', '')}`,
    }
    const data = createFetch('/crm/address', 'GET', header)
    return data
    // return HttpClient.get('/crm/address', {})
  }

  public static async addAddress(data: any) {
    // const response = createFetch('/crm/address', 'POST', '', data)
    // return response
    return HttpClient.post('/crm/address', data, {})
  }

  public static async getOneAddress(id: string) {
    // const data = createFetch(`/crm/address/${id}`, 'GET')
    // return data
    return HttpClient.get(`/crm/address/${id}`, {})
  }

  public static async updateAddress(id: string, data: any) {
    // const response = createFetch(`/crm/address/${id}`, 'PUT', '', data)
    // return response
    return HttpClient.put(`/crm/address/${id}`, data, {})
  }

  public static async delete(id: number) {
    // const response = createFetch(`/crm/address/${id}`, 'DELETE')
    // return response
    return HttpClient.delete(`/crm/address/${id}`, {})
  }

  public static async getAddressDetailWithPinCode(pinCode: any) {
    // const data = createFetch(`/crm/address/postalcode/${pinCode}`, 'GET')
    // return data
    return HttpClient.get(`/crm/address/postalcode/${pinCode}`, {})
  }
}
