import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FC, useEffect, useLayoutEffect, useRef } from 'react'
import { UseNotificationState } from '~/stores/notify'
import { QuickSearchStore } from '~/stores/quick-search'
import { CheveronLeft } from '../../icons/cheveron-left'
import { Close } from '../../icons/close'
import { SearchIcon } from '../../icons/search'
import style from './mobile-search-model.module.scss'
const Img: any = dynamic(() => import('../../img/img'))

export const MobileSearchModel: FC<{ isSmallSearch: boolean }> = (_props) => {
  const searchState = QuickSearchStore.useContainer()
  const router = useRouter()
  const gotoSearchCategories = (name: any) => {
    router.push({
      pathname: '/s',
      query: {
        catagories: `categories:=[\`${name}\`]`,
      },
    })
    searchState.setModel(false)
  }
  const refEle = useRef<HTMLDivElement>()
  const notify = UseNotificationState.useContainer()
  useLayoutEffect(() => {
    if (_props.isSmallSearch) {
      searchState.searchSuggestion()
    }
  }, [refEle, _props.isSmallSearch])

  const handleClick = (ele: any) => {
    if (ele.type == 1) {
      let link = {
        pathname: '/[slug]/p',
        query: {
          slug: ele.document.slug,
        },
      }
      // if (notify.isStore) {
      //   link.query['store'] = notify.storeName
      // }
      router.push(link)
    } else if (ele.type == 2) {
      let link = {
        pathname: '/s',
        query: {
          q: ele.document.keyword,
        },
      }
      // if (notify.isStore) {
      //   link.query['store'] = notify.storeName
      // }
      router.push(link)
    } else if (ele.type == 0) {
      let link = {
        pathname: '/s',
        query: {
          catagories: `categories:=${ele.document.parant_category}`,
        },
      }
      // if (notify.isStore) {
      //   link.query['store'] = notify.storeName
      // }
      router.push(link)
    }
    searchState.setModel(false)
  }
  return searchState.model ? (
    <div className={style.searchModel} ref={refEle}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          searchState.setModel(false)
          router.push({
            pathname: '/s',
            query: {
              q: searchState.search,
            },
          })
        }}
        className={style.search}
      >
        <button type="button" onClick={() => searchState.setModel(false)}>
          <CheveronLeft />
        </button>
        <label htmlFor="msearch">
          <input
            autoFocus
            autoComplete="off"
            onChange={(e) => {
              let t: any = e.target
              searchState.setSearch(t.value)
            }}
            value={searchState.search}
            id="msearch"
            type="search"
            placeholder="Search for products..."
          />
        </label>
        {!searchState.search && (
          <button type="submit">
            <SearchIcon iconColor="#000" />
          </button>
        )}
        {searchState.search && (
          <button type="button" onClick={() => searchState.setSearch('')}>
            <Close iconColor="#000" />
          </button>
        )}
      </form>
      <div>
        {searchState.loading ? (
          <div className="py-2 px-2 text-center">
            <b>loading...</b>
          </div>
        ) : (
          !searchState.found && (
            <div className="py-2 px-2 text-center">
              <b>No items found</b>
            </div>
          )
        )}
        <ul className={`w-1/12 ${style.searchUl}`}>
          {searchState.searchList.map((ele: any, i: number) => (
            <li key={i} className="p-2 flex" onClick={() => handleClick(ele)}>
              {ele.type == 1 && ele.document.thumb ? (
                <div
                  style={{
                    width: '50px',
                    position: 'relative',
                    objectFit: 'contain',
                  }}
                >
                  <img
                    className={style.image}
                    src={ele.document.thumb}
                    alt="document"
                    //layout="fill"
                  />
                </div>
              ) : (
                ''
              )}
              {ele.type != 1 && (
                <div className="mr-2">
                  <SearchIcon iconColor="#000" strokeWidth={1} />
                </div>
              )}
              <div
                className={`pl-1 block text-xs product_listdata ${style.parent_search}`}
              >
                {ele.type == 1 && <p>{ele.document.name}</p>}
                <span>
                  {ele.type == 1 && 'In '}
                  <span>
                    {ele.type == 2
                      ? ele.document.keyword
                      : ele.document.parant_category}
                  </span>
                </span>
              </div>
            </li>
          ))}
          {searchState.found > 20 ? (
            <li className={`${style.view_all_mobile}`}>
              <div
                onClick={() => {
                  router.push({
                    pathname: '/s',
                    query: {
                      q: searchState.search,
                    },
                  })
                  searchState.setModel(false)
                }}
              >
                View All
              </div>
            </li>
          ) : (
            <></>
          )}
        </ul>
      </div>
    </div>
  ) : (
    <></>
  )
}
