import axios from 'axios'
import { Config } from '~/config/config'
import { HttpClient, createFetch } from './htttp'

export class StoreService {
  public static async stateData() {
    const header = {
      'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
    }
    const data = createFetch(`/ci/whlocation/area_list/IN`, 'GET', header)
    return data
  }

  public static async cityData(state: any) {
    const header = {
      'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
    }
    const data = createFetch(
      `/ci/whlocation/area_list/IN/${state}`,
      'GET',
      header
    )
    return data
  }

  public static async areaData(state: any, city: any) {
    const header = {
      'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
    }
    const data = createFetch(
      `/ci/whlocation/area_list/IN/${state}/${city}`,
      'GET',
      header
    )
    return data
  }

  public static async finalData(state: any, city: any, area: any) {
    const header = {
      'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
    }
    const data = createFetch(
      `/ci/whlocation/area_list/IN/${state}/${city}/${area}`,
      'GET',
      header
    )
    return data
  }

  // public static async finalDatas(state: any, city: any, area: any) {
  //   const res = await fetch(
  //     `${Config.BASE_URL}/ci/whlocation/area_list/IN/${state}/${city}/${area}`,
  //     {
  //       method: 'GET',
  //       headers: {
  //         'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
  //       },
  //     }
  //   )
  //   const resp = await res.json()
  //   return resp
  // }

  public static async BrandSearch(brandlists: any) {
    const header = {
      'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
    }
    const data = createFetch(
      `/ci/whlocation/storearea/${brandlists}`,
      'GET',
      header
    )
    return data
  }

  public static async AreaSearch() {
    const header = {
      'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
    }
    const data = createFetch(`/ci/whlocation/storearea`, 'GET', header)
    return data
  }

  public static async Allowlocation() {
    const data = createFetch(`/api/location`, 'GET')
    return data
  }

  public static async mapData(kilometer: any, pincode: any) {
    const res = await fetch(
      `${Config.BASE_URL}/ci/whlocation/storebypin/${pincode}/${kilometer}`,
      {
        method: 'GET',
        headers: {
          'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
        },
      }
    )
    const resp = await res.json()
    return resp
  }

  public static async dataPin(kilometer: any, pincode: any) {
    const header = {
      'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
    }
    const data = createFetch(
      `/ci/whlocation/storebypin/${pincode}/${kilometer}`,
      'GET',
      header
    )
    return data
  }

  public static async sharelocation(pincode: any) {
    const header = {
      'x-api-key': Config.STORE_BASE_AUTH_TOKEN,
    }
    const data = createFetch(
      `/ci/whlocation/districtbypincode/` + pincode,
      'GET',
      header
    )
    return data
  }

  public static async getStoreItemsWithStoreId(id: any, item?: number) {
    let asonDate = new Date().toISOString().slice(0, 10).replace(/-/gi, '')
    const res = await fetch(
      `${Config.BASE_URL}/api/qr/api/apxapi/GetStockInfo?CompanyCode=${
        Config.STORE_COMPANY_CODE
      }&ItemCode=${
        !!item ? item : 0
      }&BranchCode=${id}&AsonDate=${asonDate}&BranchCategory=Website - Active`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          userid: Config.STORE_USER_ID,
          securitycode: `${Config.STORE_SECURITY_CODE}`,
        },
      }
    )
    const resp = await res.json()
    return resp
  }

  public static async getEmployeeReferer(id: any) {
    const res = await fetch(
      `${Config.BASE_URL}/api/store/api/oc3/staffdetails.php?emplid=${id}`,
      {
        method: 'GET',
        headers: {
          access_token: `${Config.EMP_REFERER_TOKEN}`,
        },
      }
    )
    const resp = await res.json()
    return resp
  }
  public static async getReceiptVoucherDetails(invoice: any, date: any) {
    const res = await fetch(
      `${Config.BASE_URL}/api/qr/api/apxapi/GetReceiptsVoucherDetails?CompanyCode=${Config.STORE_COMPANY_CODE}&sVoucherNumber=${invoice}&lVoucherDate=${date}`,
      {
        method: 'GET',
        headers: {
          userid: Config.STORE_USER_ID,
          securitycode: `${Config.STORE_SECURITY_CODE}`,
        },
      }
    )
    const resp = await res.json()
    return resp
  }
}
