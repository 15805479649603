import { FC } from 'react'

export type CheveronLeftProps = {
  iconColor?: string
  strokeWidth?: number
}
export const CheveronLeft: FC<CheveronLeftProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke={props.iconColor ? props.iconColor : 'currentColor'}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={props.strokeWidth ? props.strokeWidth : 2}
      d="M15 19l-7-7 7-7"
    />
  </svg>
)
