import { useState } from 'react'
import { createContainer } from 'unstated-next'
import { StoreService } from '~/services/stores.service'
import { Logger } from '~/util'
import { UseNotificationState } from './notify'

const ShowroomState = () => {
  const notify = UseNotificationState.useContainer()
  const [finaldata, setFinaldata] = useState([])
  const [dataLoaded, setDataLoaded] = useState(true)
  const storeList = async (state: any, city: any, area: any) => {
    if (state?.length && city?.length && area?.length) {
      await StoreService.finalData(state, city, area)
        .then(async (res) => {
          const results = await res.json()
          setFinaldata(results.data)
          // setDataLoaded(false)
        })
        .catch((err) => {
          Logger.error(err)
          // setDataLoaded(false)
        })
    }
  }

  const pinList = async (pincode: any) => {
    if (pincode == '') {
      alert('Please Enter Pincode')
    } else {
      await StoreService.dataPin(5, pincode)
        .then(async (res) => {
          const results = await res.json()
          setFinaldata(results.data)
          setDataLoaded(false)
          if (results.data == undefined) {
            // alert('not found Showroom')
            // notify.appNotification("")
          } else {
          }
        })
        .catch((err) => {
          Logger.error(err)
          setFinaldata([])
          setDataLoaded(false)
        })
    }
  }
  const kilometerList = async (kilometer: any, pincode: any) => {
    if (pincode == '') {
      alert('Please Enter Pincode')
    } else if (kilometer == '') {
      alert('Please choose kilometer')
    } else {
      await StoreService.dataPin(kilometer, pincode)
        .then(async (res) => {
          const results = await res.json()
          if (!!!results) {
            notify.appNotification('Please enter a valid pincode', 'ERROR')
          }
          if (!!results.data) {
            setFinaldata(results.data)
            setDataLoaded(false)
          }
        })
        .catch((err) => {
          if (err.response.data.status == 'error') {
            notify.appNotification(err.response.data.message, 'ERROR')
            setFinaldata([])
          }
          setDataLoaded(false)
        })
    }
  }
  const areaList = async (areaselected: any) => {
    if (areaselected.length) {
      await StoreService.BrandSearch(areaselected.replace(' (', '++('))
        .then(async (res) => {
          const result = await res.json()
          setFinaldata(result.data)
          setDataLoaded(false)
        })
        .catch((err) => {
          Logger.error(err)
          setDataLoaded(false)
        })
    }
  }
  const handleAreaChanges = async (state: any, city: any, area: any) => {
    if (state?.length && city?.length && area?.length) {
      await StoreService.finalData(state, city, area)
        .then(async (res) => {
          const result = await res.json()
          setFinaldata(result.data)
          setDataLoaded(false)
        })
        .catch((err) => {
          Logger.error(err)
          setDataLoaded(false)
        })
    }
  }

  return {
    storeList,
    finaldata,
    pinList,
    kilometerList,
    areaList,
    handleAreaChanges,
    dataLoaded,
  }
}

export const LocationState: any = createContainer(ShowroomState)
