import Link from 'next/link'
import { FC, useEffect, useRef, useState } from 'react'
import Img from '~/components/ui/img/img'
import MenuVideoCard from '~/components/ui/pim-ui/menu-video-card/menu-video-card'
import { useMounted } from '~/util/hooks'
import { SubChildTitleProps } from './subchild-menu-title.interface'
import style from './subchildmenu-title.module.scss'
import { QuickSearchStore } from '~/stores/quick-search'

type Items = {
  /** this is used to render category id  */
  category_id: any
  /** this is used to render category name */
  category_name: string
  /** this is used to render icon */
  icon: string
  /** this is used to render parent id */
  content: any
  /** this is used to render content */
  /** this is used to render parent class */
  isParentClass: boolean
}
/**
 * this is our Sub child component
 * @param {SubChildTitleProps} props this is used to define type of
 * @returns {JSX.Element} this will return a Sub child element
 */

export const SubChildTitle: FC<SubChildTitleProps> = (props) => {
  const searchState = QuickSearchStore.useContainer()
  const [items, setItems] = useState([] as Items[][])
  const [quickitems, setQuickItems] = useState([] as any)
  const [rightBanners, setRightBanners] = useState({} as any)
  const parentRef = useRef(null)
  useMounted(
    parentRef,
    () => {
      try {
        init()
      } catch (err) {}
    },
    [props.data.children]
  )
  const init = async () => {
    const newArr: any[] = []
    if (props.data.children) {
      props.data.children.forEach((ele: any, _i: any) => {
        ele.category_id != 0 &&
          newArr.push({
            category_id: ele.category_id,
            name: ele.name,
            isParentClass: true,
            redirectObject: !!ele?.linkParams?.params
              ? ele?.linkParams?.params
              : [],
            isHighlightable: ele.isHighlightable,
            colorCode: `${ele.colorCode}`,
            navigate: `[\`${props.data.name} \`]`,
            slug: `categories.lvl1:=[\`${props.data.name} > ${ele.name}\`]`,
          })
        if (ele.children) {
          ele.children.forEach((elee: any, _i: any) => {
            elee.category_id != 0 &&
              newArr.push({
                category_id: elee.category_id,
                name: elee.name,
                redirectObject: !!elee?.linkParams?.params
                  ? elee?.linkParams?.params
                  : [],
                isHighlightable: elee.isHighlightable,
                colorCode: `${elee.colorCode}`,
                navigate: `[\`${props.data.name} > ${ele.name}\`]`,
                slug: `categories.lvl2:=[\`${props.data.name} > ${ele.name} > ${elee.name}\`]`,
              })
            if (elee.children) {
              elee.children.forEach((lastchild: any, _i: any) => {
                lastchild.category_id != 0 &&
                  newArr.push({
                    category_id: lastchild.category_id,
                    name: lastchild.name,
                    isChildClass: true,
                    redirectObject: !!lastchild?.linkParams?.params
                      ? lastchild?.linkParams?.params
                      : [],
                    isHighlightable: lastchild.isHighlightable,
                    colorCode: `${lastchild.colorCode}`,
                    navigate: `[\`${props.data.name} > ${ele.name} > ${elee.name}\`]`,
                    slug: `categories.lvl3:=[\`${props.data.name} > ${ele.name} > ${elee.name} > ${lastchild.name}\`]`,
                  })
              })
            }
          })
        }
      })
    }
    const limit = 17
    const totalColumns = Math.ceil(newArr.length / limit) // 4
    setQuickItems(newArr)
    const columnData: any = []

    for (let i = 0; i < totalColumns; i++) {
      columnData.push([...newArr].slice(i * limit, i * limit + limit))
    }
    setItems(columnData)
    if (!!props.data.linkParams && !!props.data.linkParams?.sideNav) {
      setRightBanners(props.data.linkParams)
    }
  }
  const linkQuery = (path) => {
    if (!!path?.pageCode && !!path?.pageType) {
      return {
        pathname: `/[slug]/page`,
        query: {
          type: path?.pageType.toLowerCase(),
          slug: path?.pageCode,
        },
      }
    } else {
      return '/'
    }
  }
  const headerLink = (data: any, name: any, list: any) => {
    let objData: any = data?.redirectObject?.find((e) => e.isDefault == true)
    if (objData?.isClassic) {
      switch (objData?.linkType?.toUpperCase()) {
        case 'EXTERNALLINK':
          return objData?.externalUrl
        case 'PAGE':
          switch (objData?.pageType?.toUpperCase()) {
            case 'GENERAL':
              return linkQuery(objData)
            case 'CATEGORY':
              return linkQuery(objData)
            case 'BRAND':
              return linkQuery(objData)
            case 'OFFER':
              return linkQuery(objData)
          }
        default:
          return '/'
      }
    } else {
      return {
        pathname: `/s`,
        query: {
          categories: data.slug,
          // stock_status: `stock_status:=[\`In Stock\`]`,
        },
      }
    }
  }
  const bannerRedirectLink = (data: any) => {
    if (data.isClassic) {
      switch (data.linkType.toUpperCase()) {
        case 'EXTERNALLINK':
          return data.externalUrl
        case 'PAGE':
          switch (data?.pageType?.toUpperCase()) {
            case 'GENERAL':
              return linkQuery(data)
            case 'CATEGORY':
              return linkQuery(data)
            case 'BRAND':
              return linkQuery(data)
            case 'OFFER':
              return linkQuery(data)
          }
        default:
          return '/'
      }
    } else {
      return '/'
    }
  }
  return (
    <div className="flex" ref={parentRef}>
      <div className={style.sub_child_tile}>
        {items.map((column, index) => (
          <div
            className={`${style.childrenList} ${
              index % 2 == 0 ? style.backgroundColumn : 'bg-white'
            }`}
            key={index}
          >
            {column.map((data: any, i: number) => (
              <div key={i}>
                <Link
                  href={headerLink(data, data.name, column)}
                  onClick={() => {
                    props.setShowDropDown(false),
                      searchState.categorylink(quickitems, data.slug)
                  }}
                  className={`truncate block ${
                    data.isParentClass
                      ? style.childrenList__title
                      : style.childrenList__subchild_list
                  } ${data.isChildClass ? style.childClass : ''}`}
                  title={data.name}
                  style={{
                    color: data.isHighlightable ? data.colorCode : '#606060',
                  }}
                >
                  <div
                    className={`${
                      data.isParentClass && 'flex justify-between'
                    }`}
                  >
                    <span>{data.name}</span>
                    {data.isParentClass && <b />}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        ))}
      </div>
      {!!rightBanners && (
        <div
          className={`${style[rightBanners?.layout?.type]} ${style.img_list}`}
        >
          {rightBanners.sideNav?.map((item: any, index: number) => (
            <div key={index} className={style[`layoutChild_` + index]}>
              {item.isVisible && item.image && item.mediaType == 'image' ? (
                <div>
                  <Link
                    href={bannerRedirectLink(item)}
                    rel="canonical"
                    onClick={() => props.setShowDropDown(false)}
                  >
                    <img
                      src={`${item.image.url}`}
                      alt={`${item.image.url}`}
                      loading="lazy"
                      //loader={() => item.image.url}
                      //blurDataURL={item.image.url}
                      //placeholder="blur"
                    />
                  </Link>
                </div>
              ) : (
                item.video &&
                item.mediaType == 'video' && (
                  <div>
                    <MenuVideoCard {...item}></MenuVideoCard>
                  </div>
                )
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
