import style from './app-bar.module.scss'
import { AuthState } from '~/stores/auth'
import { AppBarSearch } from './app-bar-search/app-bar-serch'
import { AppBarLocation } from './app-bar-location/app-bar-location'
import { AppBarLocateStore } from './app-bar-locate-store/app-bar-locate-store'
import { AppBarAccount } from './app-bar-account/app-bar-account'
import { AppBarLogo } from './app-bar-logo/app-bar-logo'
import { FC, useEffect, useLayoutEffect, useState } from 'react'
import { SearchIcon } from '../icons/search'
import { useRouter } from 'next/router'
import { AccountDropDown } from './account-dropdown/account-dropdown'
import { Overlay } from '../overlay/overlay'
import Locator from './Common-locator'
import { CartState } from '~/stores/cart'
import { Cart } from '../icons/cart'
import { Logger, PriceFormat } from '~/util'
import { Button } from '../button/button'
import { AppBarText } from './app-bar.translations'
import Link from 'next/link'
import { QuickSearchStore } from '~/stores/quick-search'
import Img from '../img/img'
import { LanguageState } from '~/translations/computation'
import { UseNotificationState } from '~/stores/notify'
import Cookies, { remove } from 'js-cookie'
import { StoreIcon } from '../icons/store-icon'
import { StoreService } from '~/services/stores.service'
import { ExitIcon } from '../icons/exit-icon'
import { MenuState } from '~/stores/menu'
import dynamic from 'next/dynamic'
import HeaderImg from '~/components/pages/home/header-img/header-img'
import Script from 'next/script'
import { AppBarCart } from './app-bar-cart/app-bar-cart'
const Backdrop: any = dynamic(
  () => import('~/components/generals/menu/mobilemenu/backdrop/backdrop')
)
function useDebounce<T>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}
/**
 * app bar contains basic search bar, stores, cart and account menu
 * @returns {JSX.Element} returns app bar contains basic search bar, stores, cart and account menu
 */
/**
 * home page for the application
 * @category Page
 * @subcategory Home
 * @param props Page prop
 * @returns {JSX.Element} JSX
 */
interface HomePageProps {
  mobile: boolean
}
export const AppBar: any = (_props: HomePageProps) => {
  const authState = AuthState.useContainer()
  const cartState = CartState.useContainer()
  const lang = LanguageState.useContainer()
  const searchState = QuickSearchStore.useContainer()
  const [isSmall, setIsSmall] = useState(false)
  const [isSmallLocate, setIsSmallLocate] = useState(false)
  const [showSearchForm, setShowSearchForm] = useState(false)
  const [isShown, setIsShown] = useState(false)
  const [cartShowDropDown, setCartShowDropDown] = useState(false)
  const [accountDropDown, setAccountDropDown] = useState(false)
  const notify = UseNotificationState.useContainer()
  const router = useRouter()

  useLayoutEffect(() => {
    setIsSmall(window.innerWidth <= 640)
    window.onresize = (_e) => {
      setIsSmall(window.innerWidth <= 640)
    }
  }, [])
  useEffect(() => {
    if (!!authState.isLoggedIn()) {
      cartState.items = []
    }
    return () => {
      cartState.items = []
    }
  }, [authState.isLoggedIn()])
  const [showHeader, setShowHeader] = useState(true)
  const [scrollPos, setScrollPos] = useState(0)
  const debouncedVisiblity = useDebounce<boolean>(showHeader, 2000)
  const handleScroll = () => {
    setShowHeader(scrollPos > window.pageYOffset)
    setScrollPos(window.pageYOffset)
    // setScrollPos(document.body.getBoundingClientRect().top)
    // setShowHeader(document.body.getBoundingClientRect().top > scrollPos)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      setTimeout(() => {
        window.removeEventListener('scroll', handleScroll)
      }, 1500)
    }
  })
  const [_isPriceShown, setIsPriceShown] = useState(false)
  useLayoutEffect(() => {
    setIsSmallLocate(window.innerWidth <= 800)
    setIsPriceShown(window.innerWidth <= 800)
    window.onresize = (_e) => {
      setIsSmallLocate(window.innerWidth <= 800)
      setIsPriceShown(window.innerWidth <= 800)
    }
  }, [])
  const link = {
    pathname: '/cart',
    query: {
      type: 'view',
    },
  }
  const exitLink = () => {
    if (!!notify.storeName) {
      Cookies.remove('store')
    }
  }
  const menuState = MenuState.useContainer()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const drawerToggleClickHandler = () => {
    // if (_props.mobile) {
    menuState.getFullData()
    // }
    setDrawerOpen(!drawerOpen)
  }
  const backdropClickHandler = () => {
    setDrawerOpen(false)
  }
  let backdrop
  if (drawerOpen) {
    backdrop = <Backdrop close={backdropClickHandler} />
  }

  return (
    <>
      {authState.state.id && (
        <Script
          id="i"
          defer
          dangerouslySetInnerHTML={{
            __html: `
        dataLayer.push({
          'event':'login',
          'cust_id': '${authState?.state?.id}',
          'name':'${authState?.state?.firstName}',
          'mobile': '${authState?.state?.mobileno}',
          'email_id':'${authState?.state?.email}'
        })
        `,
          }}
          onError={remove}
        ></Script>
      )}

      <div
        className={`${style.topbar} ${notify.isStore && style.store_theme} ${
          showHeader ? style.header_scroll : style.header_noscroll
        }`}
      >
        <div
          className={`mx-auto flex  items-center ${style.app_bar} ${style.custom_container}`}
        >
          <div className={`${style.app_bar__image}`}>
            {!!notify.storeName ? (
              <Link href={'/s'} passHref>
                <div className="flex items-center justify-center mb-2">
                  <img
                    className={`ml-0 ${style.logo_emblem}`}
                    src="https://img.poorvika.com/logos/logo_emblem.png"
                    alt="Poorvika Store"
                    // loader={() =>
                    //   'https://img.poorvika.com/logos/logo_emblem.png'
                    // }
                    // blurDataURL={
                    //   'https://img.poorvika.com/logos/logo_emblem.png'
                    // }
                    //placeholder="blur"
                  />
                  <div className={`text-white font-semibold ml-2`}>
                    <span className={`block text-xs ${style.logo_text}`}>
                      Poorvika
                    </span>
                    <span className={`block text-xs ${style.logo_text}`}>
                      Store
                    </span>
                  </div>
                </div>
              </Link>
            ) : (
              <div className="flex items-center">
                <div id="home-page">
                  <HeaderImg
                    data={menuState.menuData}
                    style={style}
                    drawerToggleClickHandler={drawerToggleClickHandler}
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                    backdrop={backdrop}
                  />
                </div>
                <AppBarLogo />
              </div>
            )}
          </div>

          <div className={`w-5/12	 ${style.search}`}>
            <div
              className={`${
                showSearchForm ? style.search_form_show : style.search_form_hide
              }`}
            >
              <AppBarSearch setShowSearchForm={setShowSearchForm} />
            </div>
          </div>

          <div
            className={`w-5/12 flex justify-between items-stretch ${
              style.top_right
            } ${!!notify.storeName && style.store_width}`}
          >
            {/* {isSmall && (
              <div
                className={`${style.search_icon}`}
                onClick={() => searchState.setModel(true)}
              >
                {showSearchForm ? '' : <SearchIcon iconColor="#fff" />}
              </div>
            )} */}
            {isSmall && (
              <div
                className={`pl-1 mr-2 `}
                onClick={() => searchState.setModel(true)}
              >
                <span style={{ visibility: showHeader ? 'hidden' : 'visible' }}>
                  <SearchIcon iconColor="#fff" />
                </span>
              </div>
            )}

            {!!notify.storeName ? (
              !!notify.branchName ? (
                <div
                  className={`${style.store_name} space-x-1 flex items-center justify-center`}
                >
                  <StoreIcon iconColor="#fff" />
                  <div>
                    <span>
                      {notify.isStore && (
                        <p>{notify.branchName.replace('TN-', '')}</p>
                      )}
                    </span>
                    <span>Branch</span>
                  </div>
                </div>
              ) : notify.loadingText ? (
                <div
                  className={`${style.store_name} space-x-1 flex items-center`}
                >
                  <StoreIcon iconColor="#fff" />
                  <div>
                    <span>loading</span>
                  </div>
                </div>
              ) : (
                ''
              )
            ) : (
              <div
                className={` pt-1.5	${style.app_bar__locate} ${style.delivery_width} `}
              >
                <AppBarLocation />
              </div>
            )}
            {!isSmallLocate && (
              <div className={` pt-1.5 ${style.app} ${style.locate_width}`}>
                <div onClick={() => setIsShown(!isShown)}>
                  <AppBarLocateStore />
                </div>

                {isShown ? (
                  <div>
                    <Locator setIsShown={setIsShown} />
                    <Overlay
                      type="MENU_OVERLAY"
                      onClose={() => setIsShown(false)}
                    >
                      <> </>
                    </Overlay>
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}

            <div
              className={` pt-1.5 ${style.cart_dropdown} ${style.cart_width}`}
              onMouseEnter={() => setCartShowDropDown(true)}
              // onMouseLeave={() => setCartShowDropDown(false)}
            >
              <Link
                href={link}
                passHref
                className={`${style.app_bar__locate} block`}
              >
                <div
                  className={`${style.app_bar__locate_items_end} lg:float-right flex items-center`}
                >
                  <div>
                    <div className={`${style.caption}`}>
                      <span className={`${style.items_count} font-medium`}>
                        {!!authState.isLoggedIn() || cartState.items
                          ? cartState.items.length
                          : 0}
                      </span>{' '}
                      <span>Items</span>
                    </div>
                    <b className={` ${style.name}`}>
                      <button className="font-semibold break-all">
                        {!!authState.isLoggedIn() || cartState.items
                          ? cartState.cartItemTotal()
                          : PriceFormat(0)}
                      </button>
                    </b>
                  </div>
                  <Cart />
                </div>
              </Link>
              {cartShowDropDown && (
                <ul
                  className={`${
                    cartShowDropDown ? style.cart_pos : style.close_cart
                  } px-4 py-4`}
                >
                  {cartState.items.length ? (
                    <>
                      <li className={style.cart_head}>
                        <div className="flex justify-between items-center text-sm">
                          <span className="cart_icon">
                            <img
                              src="https://img.poorvika.com//new_cart_icon.svg"
                              alt="cart"
                              // loader={() =>
                              //   'https://img.poorvika.com//new_cart_icon.svg'
                              // }
                              // blurDataURL={
                              //   'https://img.poorvika.com//new_cart_icon.svg'
                              // }
                              width={20}
                              height={20}
                              //placeholder="blur"
                            />
                          </span>
                          <span className="cart_items text-center">
                            Cart Items ({cartState.items.length})
                          </span>
                          <span className="cart_price text-right">
                            {cartState.cartItemTotal()}
                          </span>
                        </div>
                      </li>
                      <li
                        className={`${
                          cartState.items.length > 4
                            ? 'h-24 overflow-y-scroll '
                            : ''
                        } content-item`}
                      >
                        <table className="table table-striped w-full">
                          <tbody>
                            {cartState.items.map((item: any, index: number) => (
                              <AppBarCart data={item} key={index} />
                            ))}
                          </tbody>
                        </table>
                      </li>
                      <li onClick={() => setCartShowDropDown(false)}>
                        <Link
                          href={{ pathname: '/cart', query: { type: 'view' } }}
                        >
                          <Button variant="ORANGE_GRADIENT">
                            PROCEED TO BUY{' '}
                          </Button>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <li className="text-sm text-center">Your Cart is empty</li>
                  )}
                </ul>
              )}
            </div>
            <div
              className={` ${style.account_width}`}
              onClick={() => {
                if (!authState.state.id) {
                  authState.setShowLoginForm(true)
                } else {
                  if (window.innerWidth < 801) {
                    router.push('/account-mobile')
                  } else {
                    {
                      accountDropDown ? <AccountDropDown /> : ''
                    }
                  }
                }
              }}
              onMouseEnter={() => setAccountDropDown(true)}
              onMouseLeave={() => setAccountDropDown(false)}
            >
              <AppBarAccount />
            </div>
            {!!notify.storeName && (
              <div className={style.exit_icon}>
                <Link href={'/'} onClick={exitLink}>
                  <ExitIcon iconColor="#fff" />
                </Link>
              </div>
            )}
          </div>
        </div>

        <div
          className={`pb-1 nav ${style.searchbutton} ${
            showHeader ? style.headerVisible : style.headerHidden
          }`}
        >
          <div className={style.app_bar__search}>
            <input
              className={`text-black ml-4`}
              onClick={() => searchState.setModel(true)}
              type="text"
              placeholder={AppBarText.SearchPlaceHolder[lang.lang]}
            />
            {!isSmall && (
              <button
                className={`${style.search_desktop}`}
                type="submit"
                aria-label="Left Align"
              >
                <SearchIcon iconColor="#000" />
              </button>
            )}

            {isSmall && (
              <button
                className="pr-1"
                type="button"
                aria-label="Left Align"
                onClick={() => searchState.setModel(true)}
              >
                <SearchIcon
                  iconColor={`${notify.isStore ? '#1fab38' : '#fe6e04'}`}
                />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
// export async function getServerSideProps({ res, req }: any) {
//   let userAgent = /Mobile/.test(req.headers['user-agent'])

//   let mode = 'web'
//   if (userAgent) {
//     mode = 'mobile_web'
//   }

//   return {
//     props: {
//       mobile: userAgent,
//     },
//   }
// }
