import Typesense from 'typesense'
import { Config } from '~/config/config'

export const TypesenseClient = (query: any) => {
  let client = new Typesense.Client({
    nodes: [
      {
        host: Config.TIME_SENSE_BASE_URL,
        port: '443',
        protocol: 'https',
      },
    ],
    apiKey: Config.TIME_SENSE_API_KEY,
    connectionTimeoutSeconds: 2,
  })
  return client.multiSearch.perform(query)
}

export const TypesenseClientFetch = async (query: any) => {
  const url = `https://${Config.TIME_SENSE_BASE_URL}/multi_search`

  const options = {
    method: 'POST',
    headers: {
      'X-TYPESENSE-API-KEY': Config.TIME_SENSE_API_KEY,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query),
  }

  try {
    const response = await fetch(url, options)
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`)
    }

    const data = await response.json()

    return data
  } catch (error) {
    console.error('Error fetching search results:', error)
  }
}
