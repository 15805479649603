import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import { SearchService } from '~/services/search.service'
import { Logger } from '~/util'

const QuickSearch = () => {
  const [search, setSearch] = useState('')
  const [compareSearch, setCompareSearch] = useState('')
  const [CompareSlug, setCompareSlug] = useState('')
  const [searchList, setSearchList] = useState([])
  const [compareList, setCompareList] = useState([])
  const [navigationList, setNavigationList] = useState([])
  const [navigationMobileList, setNavigationMobileList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [categoryMobileList, setCategoryMobileList] = useState('')
  // const [page, _setPage] = useState(1)
  const [minPrice, setMinPrice] = useState(1)
  const [maxPrice, setMaxPrice] = useState(1000000)
  const [minPriceStatic, setMinPriceStatic] = useState(1)
  const [maxPriceStatic, setMaxPriceStatic] = useState(1000000)
  const [sortBy, setSortBy] = useState('rating:desc')
  const [perPage, setPerPage] = useState(1)
  const [searchSuggestions, _setSearchSuggestions] = useState([])
  const router = useRouter()
  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (!router.query.q) {
      setSearch('')
      setCompareSearch('')
    }
  }, [router])

  const init = () => {
    setSearch(`${!!router.query.q ? router.query.q : ''}`)
    setCompareSearch(`${!!router.query.q ? router.query.q : ''}`)
  }

  const initFilters = (props: any) => {
    // let filters = props.data.results[1].facet_counts.map((el: any) => {
    //   return el
    // })
    if (!!props.data) {
      if (!!props.data.results) {
        let priceRange = props.data.results[2].facet_counts[0].counts
          .map((ele: any) => {
            return Number(ele.value)
          })
          .sort((a: any, b: any) => (a > b ? 1 : -1))
        setMinPriceStatic(priceRange[0] == -1 ? priceRange[1] : priceRange[0])
        setMinPrice(priceRange[0] == -1 ? priceRange[1] : priceRange[0])
        setMaxPriceStatic(priceRange[priceRange.length - 1])
        setMaxPrice(priceRange[priceRange.length - 1])
        Logger.log('[[filters', priceRange)
      }
    }
  }

  const [found, setFound] = useState(0)
  const [outOf, setOutOf] = useState(0)
  const [loading, setLoading] = useState(false)
  const searchSuggestion = async (que?: string) => {
    let q = !!que ? que : search
    setLoading(true)
    let perPage = 4
    if (!!window) {
      if (window.innerWidth <= 640) {
        perPage = 4
      }
    }
    let query: any = {
      searches: [
        {
          collection: 'productsnew',
          q,
          num_typos: 3,
          query_by: 'name,categories,item_code,sku',
          query_by_weights: '4,3,2,1',
          sort_by: `rating:desc,_text_match:desc`,
          page: 1,
          filter_by: 'variant_status:=1 && special_price:>-1',
          per_page: perPage,
        },
      ],
    }
    if (!!q) {
      query.searches.push({
        collection: 'keywordsdata',
        q,
        num_typos: 0,
        query_by: 'keyword',
        sort_by: `_text_match:desc`,
        page: 1,
        per_page: 4,
      })
    }
    try {
      const data = await SearchService.searchV1(query)
      Logger.log('TYPESENSE', data)
      const suggest = () =>
        !!data.results[1]
          ? data.results[1].hits.map((el: any) => ({
              ...el,
              type: 2,
              href: {
                pathname: '/s',
                query: { q: el.document.keyword },
              },
            }))
          : []
      Logger.log('LINKS', 'PREFIX')
      let sList = [
        ...suggest(),
        ...data.results[0].hits.map((el: any) => ({
          ...el,
          type: 1,
          href: {
            pathname: `/[slug]/p`,
            query: { slug: el.document.slug },
          },
        })),
        ...[
          ...new Map(
            data.results[0].hits.map((item: any) => [
              item.document.parant_id,
              item,
            ])
          ).values(),
        ].map((el: any) => ({
          ...el,
          type: 0,
          href: {
            pathname: `/s`,
            query: {
              categories: `categories:=[\`${String(el.document.parant_category)
                .replace('[', '')
                .replace(']', '')}\`]`,
            },
          },
        })),
      ]
      Logger.log('LINKS', sList)
      setSearchList(sList)
      setFound(data.results[0].found)
      setOutOf(data.results[0].out_of)
      setLoading(false)
    } catch (err) {
      Logger.error(err)
      setLoading(false)
    }
  }
  const categorylink = (list, val) => {
    let v = 0
    let array = []
    list.map((i: any, j: any) => {
      let cArr = String(val).split('=')
      if (cArr[1] == i.navigate) {
        array.push(i)
        v = v + 1
      }
    })
    setNavigationList(array)
    setCategoryList(list)
  }
  const categorymobilelink = (list, val, mobilelist) => {
    setNavigationMobileList([])
    let array = []
    let linkfilter = String(val).split('=')
    list.map((i: any, j: any) => {
      if (i[3] == val) {
        array.push(i[1])
        setCategoryMobileList(i[7])
      }
    })
    if (array[0]?.length == undefined && linkfilter[0] == 'categories.lvl1:') {
      setNavigationMobileList(list)
      setCategoryMobileList(linkfilter[1].replace('[`', '').replace('`]', ''))
    }
    setNavigationList(array[0])
    setCategoryList(list)
  }
  const comparesearch = async (que?: string) => {
    let q = !!que ? que : search
    setLoading(true)
    let perPage = 4
    if (!!window) {
      if (window.innerWidth <= 640) {
        perPage = 4
      }
    }
    let query: any = {
      searches: [
        {
          collection: 'productsnew',
          q,
          num_typos: 3,
          query_by: 'name,categories,item_code,sku',
          query_by_weights: '4,3,2,1',
          sort_by: `_text_match:desc`,
          page: 1,
          filter_by:
            'variant_status:=1 && special_price:>-1 && stock_status:=In Stock',
          per_page: perPage,
        },
      ],
    }
    if (!!q) {
      query.searches.push({
        collection: 'keywordsdata',
        q,
        num_typos: 0,
        query_by: 'keyword',
        sort_by: `_text_match:desc`,
        page: 1,
        per_page: 4,
      })
    }
    try {
      const data = await SearchService.searchV1(query)
      let sLists = [
        ...data.results[0].hits.map((el: any) => ({
          ...el,
          type: 1,
          href: '',
        })),
      ]
      setCompareList(sLists)
      setLoading(false)
    } catch (err) {
      Logger.error(err)
      setLoading(false)
    }
  }
  useEffect(() => {
    searchSuggestion()
    comparesearch()
    return () => {}
  }, [])

  const [model, setModel] = useState(false)

  useEffect(() => {
    if (!!window) {
      if (window.innerWidth <= 640) {
        if (model) {
          searchSuggestion()
        }
      }
    }
    return () => {}
  }, [search, model])

  return {
    model,
    setModel,
    found,
    outOf,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    sortBy,
    setSortBy,
    perPage,
    setPerPage,
    searchSuggestions,
    searchSuggestion,
    comparesearch,
    search,
    setSearch,
    init,
    searchList,
    setSearchList,
    compareList,
    setCompareList,
    initFilters,
    compareSearch,
    setCompareSearch,
    maxPriceStatic,
    minPriceStatic,
    setCompareSlug,
    CompareSlug,
    categorylink,
    categorymobilelink,
    navigationList,
    setNavigationList,
    categoryList,
    setCategoryList,
    setCategoryMobileList,
    categoryMobileList,
    setNavigationMobileList,
    navigationMobileList,
  }
}

export const QuickSearchStore: any = createContainer(QuickSearch)
