import { FC } from 'react'
import { UseNotificationState } from '~/stores/notify'
import { Spinner } from '../spinner/spinner'
import { ButtonProps } from './button.interface'
import style from './button.module.scss'

export const Button: any = (props) => {
  const variantClassMap: any = {
    NORMAL: '',
    ROUNDED: 'rounded',
    CIRCULAR: 'rounded-full',
    ORANGE_GRADIENT: 'button--orange-gradient',
    GREEN_GRADIENT: 'button--green-gradient',
    OUTLINED_ROUNDED: 'button--outlined-rounded',
    undefined: '',
  }
  const text = props.children ? props.children : 'button'
  // const text = 'button'

  const onClick = (_e: any) => {
    props.onClick ? props.onClick() : ''
  }
  const notify = UseNotificationState.useContainer()
  return (
    <button
      disabled={props.disabled}
      onClick={(e) => onClick(e)}
      type={props.type ? props.type : 'button'}
      style={{
        maxWidth: `${props.width}`,
        backgroundColor: props.color,
        color: props.textColor,
      }}
      className={`button bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 width:auto ${
        props.className
      } ${variantClassMap[props.variant]} ${
        style[variantClassMap[props.variant]]
      } ${style.btnClass} ${
        notify.isStore && style[variantClassMap.GREEN_GRADIENT]
      }`}
      {...props}
    >
      {props.loading ? (
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Spinner size={props.spinnerWidth} thick={props.spinnerThickness} />
          {props.loadingText ? (
            <span className="pl-2">{props.loadingText}</span>
          ) : (
            ''
          )}
        </span>
      ) : (
        text
      )}
    </button>
  )
}
export default Button
