import { HttpClient } from './htttp'
import { TypesenseClient, TypesenseClientFetch } from './typesense'

export class SearchService {
  public static async searchV1(data: any) {
    return await TypesenseClient(data)
  }

  public static async searchV1Fetch(data: any) {
    return await TypesenseClientFetch(data)
  }
}
