import { env } from 'process'

/**
 * this object contains all the configuration for the app
 */
export const Config = {
  ENVIRONMENT: process.env.ENVIRONMENT as
    | 'testing'
    | 'production'
    | 'stage'
    | 'develop'
    | 'uat'
    | 'pimlive',

  BASE_URL: process.env.BASE_URL,
  TOKEN_NAME: process.env.TOKEN_NAME,
  TOKEN_EXPIRE: process.env.TOKEN_EXPIRE,
  EMI: {
    MERCHANT_ID: process.env.EMI_MERCHANT_ID,
    MERCHANT_ACCESS_CODE: process.env.EMI_MERCHANT_ACCESS_CODE,
  },
  PAYMENT: {
    BASE_URL: process.env.PAYMENT_BASE_URL,
  },
  TIME_SENSE_API_KEY: process.env.TIME_SENSE_API_KEY,
  TIME_SENSE_BASE_URL: process.env.TIME_SENSE_BASE_URL,
  TIME_SENSE_BASE_URL_1: process.env.TIME_SENSE_BASE_URL_1,
  TIME_SENSE_BASE_URL_2: process.env.TIME_SENSE_BASE_URL_2,
  STORE_BASE_URL: process.env.STORE_BASE_URL,
  LOCATE_BASE_URL: process.env.LOCATE_BASE_URL,
  STORE_BASE_AUTH_TOKEN: process.env.STORE_BASE_AUTH_TOKEN,
  REVIEW_END_POINT: process.env.REVIEW_END_POINT,
  CRM_BASE_URL: process.env.CRM_BASE_URL,
  PIM_BASE_URL: process.env.NEXT_ADMIN_API,
  IP_BASE_URL: process.env.IP_BASE_URL,
  FB_TRACKING_CODE: process.env.FB_TRACKING_CODE,
  EXP_TIME_HOUR: process.env.EXP_TIME_HOUR,
  COOKIE_DOMAIN: process.env.COOKIE_DOMAIN,
  GOOGLE_TAG_MANAGER: process.env.GOOGLE_TAG_MANAGER,
  PAYMENT_REDIRECT_URL: process.env.PAYMENT_REDIRECT_URL,
  PINCODE_AUTH_TOKEN: process.env.PINCODE_AUTH_TOKEN,
  CRM_TOKEN: process.env.CRM_TOKEN,
  SITE_URL: process.env.SITE_URL,
  GOOGLE_TAG_MANAGER_EVENT: process.env.GOOGLE_TAG_MANAGER_EVENT,
  STORE_QR_API: process.env.STORE_QR_API,
  STORE_USER_ID: process.env.STORE_USER_ID,
  STORE_SECURITY_CODE: process.env.STORE_SECURITY_CODE,
  STORE_COMPANY_CODE: process.env.STORE_COMPANY_CODE,
  EMP_REFERER_TOKEN: process.env.EMP_REFERER_TOKEN,
  OMS_ORDER_API: process.env.OMS_ORDER_API,
  CRM_BSE_URL: process.env.CRM_BSE_URL,
  CRM_AUTH_URL: process.env.CRM_AUTH_URL,
  PUBLIC: process.env.PUBLIC,
  PRIVATE: process.env.PRIVATE,
  STACK_STATUS_URL: process.env.STACK_STATUS_URL,
  STACK_STATUS_TOKEN: process.env.STACK_STATUS_TOKEN,
  PIM_OFFERS_URL: process.env.PIM_OFFERS_URL,
  NOTIFY_ME_SECURITY_CODE: process.env.NOTIFY_ME_SECURITY_CODE,
  OFFERS_KEY: process.env.OFFERS_KEY,
  HOME_PAGE_SLUG: process.env.HOME_PAGE_SLUG,
}
