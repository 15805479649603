import Image, { ImageProps } from 'next/image'
import { FC, useState } from 'react'
//import { Logger } from '~/util'
import style from './../../../styles/home.module.scss'
type Img = {
  rWidth?: {
    mobileweb: number
    web: number
  }
  rHeight?: {
    mobileweb: number
    web: number
  }
  deviceType?: 'mobileweb' | 'web'
  blurEffect?: boolean
}

/**
 * this Img tag created instead of normal img tag with image consistency.
 */
const Img: FC<ImageProps & Img & { withoutBase?: boolean }> = (prop) => {
  let props = { ...prop }
  delete props.rWidth
  delete props.rHeight
  delete props.deviceType
  delete props.loading
  delete props.src
  const [loaded, setLoaded] = useState(false)
  const imgUrl = (url: string, qty: string, height: string) =>
    String(`${url}`).replace(
      /\.com/g,
      `.com/cdn-cgi/image/width=${
        prop.rWidth && prop.rWidth[props.deviceType]
      },height=${prop.rHeight && prop.rHeight[props.deviceType]})`
    )

  const wepUrlMatch = String(prop.src).match(/\.([0-9a-z]+)(?:[\?#]|$)/i)
  const webpImg = () => !!prop.src && !!wepUrlMatch && wepUrlMatch[1] == 'webp'
  const width = () => prop.rWidth && prop.rWidth[prop.deviceType]
  const height = () => prop.rHeight && prop.rHeight[prop.deviceType]
  const srcURL = imgUrl(
    `${prop.src}`,
    String(prop?.rWidth && prop.rWidth[props?.deviceType]),
    String(prop?.rHeight && prop.rHeight[props?.deviceType])
  )
  const altUrl = () => {
    const regex = /[A-Za-z0-9_\-\.]+(?=\.[A-Za-z0-9]+$)/
    var path = srcURL
    var result = path.match(regex)
    return !!srcURL && !!result ? result[0].replace(/[^\w\s]/gi, ' ') : 'image'
  }

  const loader = ({ src, width, quality }) => {
    let ind = String(prop.src).indexOf('.com') + 4
    let domain = String(prop.src).slice(0, ind)
    let path = String(prop.src).slice(ind)
    let url = `${domain}/cdn-cgi/image/width=${
      prop.rWidth && prop.rWidth[prop.deviceType]
    },height=${prop.rHeight && prop.rHeight[prop.deviceType]},quality=${
      quality || 75
    }${path}`
    return webpImg() ? `${prop.src}` : `${url}`
  }
  const img = 'https://img.poorvika.com/common/NoImageAvailable.jpg'
  return (
    <Image
      {...props}
      loader={loader}
      src={webpImg() ? prop.src : srcURL}
      // src={webpImg() && prop.src == "undefined" ? img : prop.src || webpImg() && srcURL }
      width={width()}
      height={height()}
      alt={altUrl()}
      //placeholder="blur"
      sizes="33vw"
      className={loaded ? `${style.blur}` : ''}
      onLoadingComplete={() => {
        prop.blurEffect ? '' : setLoaded(true)
      }}
    />
  )
}

export default Img
