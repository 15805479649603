import { LanguageState } from '~/translations/computation'
import { FooterText } from './footer.translation'
import style from './footer.module.scss'
import Link from 'next/link'
import SocialData from '../cards/sociallinks-card/sociallinks-card'
import dynamic from 'next/dynamic'
const Img: any = dynamic(() => import('next/image'))
export const Footer = () => {
  const L = LanguageState.useContainer()

  const supportList = [
    { label: FooterText.ContactUs, to: '/contact-us' },
    { label: FooterText.SiteMap, to: '/sitemap' },
    {
      label: FooterText.OneAssist,
      to: 'https://oneassist.in/faq/mobile-protection/',
    },
  ]
  const policiesList = [
    { label: FooterText.TAndC, to: '/terms-and-conditions' },
    { label: FooterText.PrivacyPolicy, to: '/privacy-policy' },
    { label: FooterText.CSRPolicy, to: '/csr-and-whistle-blower-policy' },
    { label: FooterText.AboutUs, to: '/return-replace' },
  ]
  const shopList = [
    { label: FooterText.Aboutus, to: '/about-us' },
    { label: FooterText.OurStores, to: '/showrooms' },
    { label: FooterText.Servicecentres, to: '/service-center' },
  ]
  const opportunitiesList = [
    { label: FooterText.BeAnAffiliate, to: 'https://jobs.poorvika.com/' },
    // { label: FooterText.BeAnInfluencer, to: '/influencer' },
    // { label: FooterText.CreateContent, to: '/create-content' },
  ]
  const footerLists = [
    {
      title: FooterText.Support,
      list: supportList,
    },
    {
      title: FooterText.Policies,
      list: policiesList,
    },
    {
      title: FooterText.Opportunities,
      list: opportunitiesList,
    },
    {
      title: FooterText.Shop,
      list: shopList,
    },
  ]
  const aboutus = {
    About: FooterText.AboutUsText,
  }

  return (
    <footer className={style.foot}>
      <div className={style.footer_container}>
        <SocialData />
        <div>
          <div className={`${style.footer_grid}`}>
            <div className={`${style.grid__item}`}>
              <div>
                <b className={style.heading}>
                  <h3> {FooterText.ContactInformation[L.lang].toString()}</h3>
                </b>
                <div className={`mb-2 ${style.sub_heading}`}>
                  <h3>{FooterText.HeadOffice[L.lang]}</h3>
                </div>
                <div className={`${style.text} `}>
                  {FooterText.HeadOfficeDetail[L.lang]}
                </div>
                <div className={`${style.text}`}>
                  {FooterText.HeadOfficeAddress[L.lang]}
                </div>
              </div>
            </div>
            {footerLists.map((items: any, j: any) => (
              <div key={j} className={`${style.grid__item}`}>
                <b className={style.heading}>{items.title[L.lang]}</b>
                <ul key={`new-key-${j}`}>
                  {items.list.map((ele: any, i: number) => (
                    <li key={`list-${j}-${i}`}>
                      <Link href={ele.to} passHref>
                        {ele.label[L.lang]}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div
            className={`p-4 sm:p-9 md:p-9 lg:p-9 leading-7 ${style.about_us_content}`}
          >
            <h3>
              <b>About Us: </b>
            </h3>
            <p className="text-justify inline font-light">
              {aboutus.About[L.lang]}
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
            <div className="...">
              <div
                className={` ml-5 sm:ml-0 md:ml-0 lg:ml-0 text-left sm:text-center md:text-center lg:text-center`}
              >
                <div className="app-download-img">
                  <ul className={`inline-flex`}>
                    <li>
                      <Link href="https://play.google.com/store/apps/details?id=com.poorvika.ecommerce.online">
                        <img
                          src="https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/google-play.png"
                          alt="gogle-play"
                          //priority
                          // loader={() =>
                          //   'https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/google-play.png'
                          // }
                          width={144}
                          height={44}
                          // blurDataURL={
                          //   'https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/google-play.png'
                          // }
                          //placeholder="blur"
                        />
                      </Link>
                    </li>
                    <li className="">
                      <Link href="https://apps.apple.com/us/app/poorvika/id1539862976">
                        <img
                          src="https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/app-store.png"
                          alt="app-store"
                          //priority
                          // loader={() =>
                          //   'https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/app-store.png'
                          // }
                          width={123}
                          height={44}
                          // blurDataURL={
                          //   'https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/app-store.png'
                          // }
                          //placeholder="blur"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="...">
              <div
                className={`ml-5 sm:ml-0 md:ml-0 lg:ml-0 text-left sm:text-center md:text-center lg:text-center`}
              >
                <h6>Our EMI Partners</h6>
                <ul className={`inline-flex`}>
                  <li className={`p-3 `}>
                    <img
                      src="https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/bajaj.png"
                      alt="bajaj"
                      // priority
                      // loader={() =>
                      //   'https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/bajaj.png'
                      // }
                      width={72}
                      height={19}
                      // blurDataURL={
                      //   'https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/bajaj.png'
                      // }
                      //placeholder="blur"
                    />
                  </li>
                  <li className={`p-3`}>
                    <img
                      src="https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/foot-hdfc.png"
                      alt="foot-hdfc"
                      // priority
                      // loader={() =>
                      //   'https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/foot-hdfc.png'
                      // }
                      width={68}
                      height={22}
                      // blurDataURL={
                      //   'https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/foot-hdfc.png'
                      // }
                      //placeholder="blur"
                    />
                  </li>
                  <li className={`p-3`}>
                    <img
                      src="https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/home-credit.png"
                      alt="home-credit"
                      // priority
                      // loader={() =>
                      //   'https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/home-credit.png'
                      // }
                      width={52}
                      height={22}
                      // blurDataURL={
                      //   'https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/home-credit.png'
                      // }
                      //placeholder="blur"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="...">
              <div
                className={`ml-5 sm:ml-0 md:ml-0 lg:ml-0 text-left sm:text-center md:text-center lg:text-center`}
              >
                <h6>Payment Option</h6>
                <ul className={`inline-flex`}>
                  <li className={`p-3`}>
                    <img
                      src="https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/foot-Payment-option.png"
                      alt="foot-payment-option"
                      //priority
                      // loader={() =>
                      //   'https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/foot-Payment-option.png'
                      // }
                      width={700}
                      height={30}
                      // blurDataURL={
                      //   'https://img.poorvika.com/cdn-cgi/image/width=772,height=454,quality=50/common/foot-Payment-option.png'
                      // }
                      //placeholder="blur"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="p-2 sm:p-10 sm:pb-2 sm:pt-1 md:p-10 md:pb-2 md:pt-1 lg:p-10 lg:pb-2 lg:pt-1 leading-7 grid grid-cols-1 gap-3">
            <div className={` mb-1 text-left text-sm text-black font-normal`}>
              Copyright © 2023 Poorvika Mobiles Private Limited | All Rights
              Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
