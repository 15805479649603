// @ts-ignore
// @ts-nocheck
import { FC, useRef } from 'react'
import Slider from 'react-slick'

interface SocialautoplayScrollWrapperProps {
  /**
   * array of children
   */
  children: any[]
  /**
   * title for the scrollbar  j
   */
}

export const SocialautoplayScrollWrapper: FC<SocialautoplayScrollWrapperProps> = ({
  children,
}) => {
  const slider = useRef(null)
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: children.length > 8 ? 8 : children.length,
          slidesToScroll: children.length > 8 ? 8 : children.length,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: children.length > 8 ? 8 : children.length,
          slidesToScroll: children.length > 8 ? 8 : children.length,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: children.length > 8 ? 8 : children.length,
          slidesToScroll: children.length > 8 ? 8 : children.length,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: children.length > 6 ? 6 : children.length,
          slidesToScroll: children.length > 6 ? 6 : children.length,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 536,
        settings: {
          slidesToShow: children.length > 6 ? 6 : children.length,
          slidesToScroll: children.length > 6 ? 6 : children.length,
        },
      },
    ],
  }

  return (
    <div>
      <Slider {...settings} ref={slider} aria-hidden="false">
        {children}
      </Slider>
    </div>
  )
}
