import React, { useEffect } from 'react'
import { FC, useState } from 'react'
import { Model } from '~/components/ui/model/model'
import Img from '../../img/img'
import style from './menu-video-card.module.scss'

type MenuVideoCardProps = {
  _id?: string
  isDefault?: boolean
  isVisible?: boolean
  mediaType?: string
  page?: string
  video?: {
    controls?: boolean
    img?: string
    loop?: true
    playerType?: string
    videoID: string
  }
}
export const MenuVideoCard: FC<MenuVideoCardProps> = (props) => {
  const variantClassMap: any = {
    Youtube: 'https://www.youtube.com/embed/',
    Vimeo: 'https://player.vimeo.com/video/',
    Dailymotion: 'https://www.dailymotion.com/embed/video/',
  }
  const imageVariantMap: any = {
    Youtube: `https://img.youtube.com/vi/${props.video.videoID}/0.jpg`,
    Vimeo: `https://vumbnail.com/${props.video.videoID}.jpg`,
    Dailymotion: `https://www.dailymotion.com/thumbnail/video/${props.video.videoID}`,
  }

  const [showVideo, setShowVideo] = useState(false)
  return (
    <div
      className={style.menu_video_card}
      onMouseLeave={() => setShowVideo(false)}
    >
      <div className="m-auto text-center relative">
        <img
          src={imageVariantMap[props.video.playerType]}
          alt="play_video"
          // loader={() => imageVariantMap[props.video.playerType]}
          // blurDataURL={imageVariantMap[props.video.playerType]}
          //placeholder="blur"
        />
        <button
          onClick={() => setShowVideo(true)}
          className={style.img_abs_menu}
        >
          <img
            className={style.img_menu}
            src={`https://img.poorvika.com//play_video.png`}
            // loader={() => `https://img.poorvika.com//play_video.png`}
            // blurDataURL={`https://img.poorvika.com//play_video.png`}
            //placeholder="blur"
            alt="play_video"
          />
        </button>
      </div>
      {showVideo && (
        <Model closeModel={() => setShowVideo(false)} model={showVideo}>
          <iframe
            src={`${variantClassMap[props.video.playerType]}${
              props.video.videoID
            }`}
            title="video player"
            allow="fullscreen; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </Model>
      )}
    </div>
  )
}
export default MenuVideoCard
