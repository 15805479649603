import 'tailwindcss/tailwind.css'
import '@/styles/globals.scss'
import '@/styles/slick1.scss'
import '@/styles/slick-theme1.scss'
import 'rc-slider/assets/index.css'
import NextNProgress from 'nextjs-progressbar'
import { Workbox } from 'workbox-window'
import { useEffect, useLayoutEffect, useState } from 'react'
import { AppWrapper } from '@/components/middlewares/app-wrapper'
import { AuthState } from '@/stores/auth'
import { LanguageState } from '@/translations/computation'
import { Footer } from '@/components/ui/footer/footer'
// import { MobileAppBar } from '~/components/ui/mobile/mobile-app-bar/mobile-app-bar'
import { AppBar } from '~/components/ui/app-bar/app-bar'
import { SearchState } from '~/stores/search'
import { LoginRegisterForm } from '~/components/ui/forms/login-register/login-register-form'
import { CartState } from '~/stores/cart'
import { MenuState } from '~/stores/menu'
import { DesktopMenu } from '~/components/generals/menu/desktopmenu'
import { UseNotificationState } from '~/stores/notify'
import { FavState } from '~/stores/favourite'
// import { SearchOverlay } from '~/components/generals/search-overlay/search-overlay'
import { OrderDetailState } from '~/stores/order-detail'
import { LocationState } from '~/stores/showroom'
//sentry error tracking
// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
import { ShowcaseCarouselState } from '~/stores/showcase-carousel'
import { ExpandedShowCaseCarousel } from '~/components/ui/carousel/showcase-carousel/expanded-showcasecarousel/expanded-showcasecarousel'
import { MobileSearchModel } from '~/components/ui/mobile/mobile-search-model/mobile-search-model'
import { QuickSearchStore } from '~/stores/quick-search'
import { Config } from '~/config/config'
import { Logger } from '~/util'
import Script from 'next/script'
import { ReviewState } from '~/stores/review'
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'
import MobileAppBar from '~/components/ui/mobile/mobile-app-bar/mobile-app-bar'
import style from './../styles/home.module.scss'
import { ImageStore } from '~/stores/img'
import { RecentlyViewedState } from '~/stores/recently-viewed'
import { DetailStore } from '~/stores/detail'
import { PriceState } from '~/stores/price'

const env = process.env.NODE_ENV

/**
 * Root directory
 * @category Wrapper
 * @subcategory All
 * @param {AppProps} props Application props
 * @returns {JSX.Element} JSX Html page wrapped around common and configuration elements
 */
function MyApp(props: any) {
  const { Component, pageProps, router }: any = props

  useEffect(() => {
    // if (env === 'production') {
    if (window.navigator.serviceWorker) {
      const wb = new Workbox('sw.js', { scope: '/' })
      wb.register()
        .then((_res) => {})
        .catch(Logger.error)
    }
    // }
    return () => {}
  }, [])

  // Sentry.init({
  //   dsn:
  //     'https://841b515005bf49198f1b2b87f6e111d2@o1082930.ingest.sentry.io/6104651',
  //   integrations: [new Integrations.BrowserTracing()],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  // })
  const [isSmallSearch, setIsSmallSearch] = useState(false)
  const [_isSmallAppbar, setIsSmallAppbar] = useState(false)
  useLayoutEffect(() => {
    setIsSmallAppbar(window.innerWidth <= 736)
    setIsSmallSearch(window.innerWidth <= 640)
    window.onresize = (_e) => {
      setIsSmallAppbar(window.innerWidth <= 736)
      setIsSmallSearch(window.innerWidth <= 640)
    }
  }, [])
  return (
    <UseNotificationState.Provider>
      <PriceState.Provider>
        <QuickSearchStore.Provider>
          <ShowcaseCarouselState.Provider>
            <MenuState.Provider>
              <LocationState.Provider>
                <AuthState.Provider>
                  <RecentlyViewedState.Provider>
                    <DetailStore.Provider>
                      <OrderDetailState.Provider>
                        <FavState.Provider>
                          <ReviewState.Provider>
                            <CartState.Provider>
                              <LanguageState.Provider initialState="en-IN">
                                <SearchState.Provider>
                                  <Script
                                    id="gtag-base"
                                    strategy="afterInteractive"
                                    defer
                                    dangerouslySetInnerHTML={{
                                      __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${Config.GOOGLE_TAG_MANAGER}');
          `,
                                    }}
                                  />
                                  <AppWrapper>
                                    <div
                                      className={`top-0 left-0 right-0 ${style.app_headfixed}`}
                                      style={{ zIndex: 3 }}
                                    >
                                      {!Component.lean && <AppBar />}
                                      {/* <DesktopMenu />
                              {Component.pageName == 'home' && <MobileAppBar />} */}
                                      {
                                        // isSmallAppbar ? (
                                        //   Component.pageName == 'home' && <MobileAppBar />
                                        // ) :
                                        Component.pageName == 'home' ||
                                        Component.pageName == 'category' ||
                                        Component.pageName == 'Pimpage' ||
                                        Component.pageName == 'product' ? (
                                          <DesktopMenu />
                                        ) : (
                                          ''
                                        )
                                      }
                                    </div>
                                    <LoginRegisterForm variant="LOGIN" />
                                    {/* <SwitchLanguage></SwitchLanguage> */}
                                    <div style={{ minHeight: '70vh' }}>
                                      <NextNProgress
                                        color="#fff"
                                        startPosition={0.3}
                                        stopDelayMs={200}
                                        height={4}
                                        showOnShallow={true}
                                      />
                                      <Component {...pageProps} />
                                    </div>

                                    {Component.pageName == 'home'
                                      ? ''
                                      : !Component.lean && <Footer />}

                                    <ExpandedShowCaseCarousel></ExpandedShowCaseCarousel>
                                    {isSmallSearch ? (
                                      <MobileSearchModel
                                        isSmallSearch={isSmallSearch}
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </AppWrapper>
                                </SearchState.Provider>
                              </LanguageState.Provider>
                            </CartState.Provider>
                          </ReviewState.Provider>
                        </FavState.Provider>
                      </OrderDetailState.Provider>
                    </DetailStore.Provider>
                  </RecentlyViewedState.Provider>
                </AuthState.Provider>
              </LocationState.Provider>
            </MenuState.Provider>
          </ShowcaseCarouselState.Provider>
        </QuickSearchStore.Provider>
      </PriceState.Provider>
    </UseNotificationState.Provider>
  )
}

export default MyApp
