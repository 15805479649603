import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'
import { OrderService } from '~/services/order.service'
import { Logger } from '~/util'
import { AuthState } from './auth'
import { UseNotificationState } from './notify'

const OrderDetail = () => {
  const router = useRouter()
  const [status, setStatus] = useState('')
  const [rating, setRating] = useState(0)
  const [cancelType, setCancelType] = useState('')
  const [orderProductItemDetails, setOrderProductItemDetails] = useState(
    null as any
  )
  const [otherProduct, setOtherProduct] = useState({} as any)
  const [otherProductList, setOtherProductList] = useState([] as any)
  const [orderProductPolicy, setOrderProductPolicy] = useState({} as any)
  const [cancelledOrdersTotalData, setCancelledOrdersTotalData] = useState(
    {} as any
  )
  const [cancelledResponseDatas, setCancelledResponseDatas] = useState(
    [] as any
  )
  const [orderSummary, setOrderSummary] = useState([] as any)
  const [orderhistory, setOrderhistory] = useState([] as any)
  const [orderstepper, setOrderstepper] = useState([] as any)
  const [orderdetailshow, setOrderdetailshow] = useState([] as any)
  const [thankScreen, setThankScreen] = useState([] as any)
  const [thankScreenProduct, setThankScreenProduct] = useState([] as any)
  const [tcModel, setTcModel] = useState(false)
  const [shippingDetails, setShippingDetails] = useState({} as any)
  const [trackingDetails, setTrackingDetails] = useState([] as any)
  const [trackingStatusMsg, setTrackingStatusMsg] = useState([] as any)
  const [dataLoaded, setDataLoaded] = useState(false)
  const notify = UseNotificationState.useContainer()
  const [trackingIdPopup, setTrackingIdPopup] = useState(false)

  const [newPost] = useState({
    cancel_reason: '',
    comment: '',
  })

  const [expanded, setExpanded] = useState(false)

  const breadCrub = useMemo(
    () => [
      { label: 'Home', link: { pathname: '/', query: {} } },

      {
        label: 'My Account',
        link: { pathname: '/account', query: { type: 'profile' } },
      },
      {
        label: 'My Orders',
        link: { pathname: '/orders', query: { type: 'history' } },
      },
      {
        label: 'Order Detail',
        link: {
          pathname: router.route.replace('[id]', String(router.query.id)),
          query: {},
        },
      },
    ],
    []
  )
  const [invoice, setInvoice] = useState('')
  const [cancelTypeList, setCancelTypeList] = useState([])
  useEffect(() => {
    const replaceType = { RETURN: 1, REPLACEMENT: 2, CANCEL: '' }
    if (!!cancelTypeList.length) {
      if (orderProductItemDetails.return_or_replace) {
        setCancelType(replaceType[cancelTypeList[0].policy_type])
      }
    } else {
      setCancelType('')
    }
  }, [cancelTypeList, orderProductItemDetails])
  const orderItemData = async (
    order_id: any,
    product_id: any,
    item_code: any,
    order_product_id: any
  ) =>
    await OrderService.orderProductDetails({
      order_id,
      product_id,
      item_code,
      order_product_id: `${order_product_id}`,
    })
      .then(async (_res) => {
        const result = await _res.json()
        Logger.log('[ORDER DETAIL]', result)
        setOtherProductList(result.data.other_product.other_products)
        setOrderProductItemDetails(result.data)
        setStatus(result.data.order_status)
        setOtherProduct(result.data.other_product)
        setOrderdetailshow(result.data.product)
        setOrderhistory(result.data.order_history)
        setOrderSummary(result.data.total)
        //
        // setCancelType(
        const cancelTyp = result.data.order_product_policy
          .filter((e: { returnOrReplace: any }) => e.returnOrReplace)
          .filter((e: { policy_type: any }) =>
            ['RETURN', 'REPLACEMENT'].includes(e.policy_type)
          )
        setCancelTypeList(cancelTyp)
        // alert(`${_res.data.data.return_or_replace} ${JSON.stringify(cancelTyp)}`)

        setOrderSummary(result.data.total)

        let productPolicy = result.data.order_product_policy.find(
          (o) => o.policy_type.toUpperCase() == 'WARRANTY'
        )
        if (result.data.order_product_policy.length) {
          setOrderProductPolicy(productPolicy)
        }
        setInvoice(result.data.invoice_url)
        if (result.data.feature_order_status == '') {
          setOrderstepper(result.data.order_history)
        } else {
          setOrderstepper(result.data.feature_order_status)
        }
      })
      .catch((err) => {
        if (err.response?.status == 404) {
          router.push('/orders')
        }
        Logger.error('status', err.response?.status)
      })

  const cancelledOrderDetailsData = async (_order_id: any) => {}

  const placedOrdersData = async (orderId: any) => {
    await OrderService.thankScreen(orderId)
      .then((_res) => {
        setThankScreen(_res.data.data)
        setThankScreenProduct(_res.data.data.products)
        setDataLoaded(false)
      })
      .catch(Logger.error)
    setDataLoaded(false)
  }

  useEffect(() => {
    init()
    return () => {}
  }, [])

  /**
   * this method contain all the state initialization
   */
  const init = () => {
    //
  }
  const [cancelPost, setCancelPost] = useState({
    cancel_reason: '',
    comment: '',
  })
  const updateCancelReason = (i: number, value: any) => {
    const cancelItems: any = cancelledResponseDatas
    cancelItems[i].comment = value
    setCancelledResponseDatas(cancelItems)
  }
  const authState = AuthState.useContainer()

  const postCancel = async () => {
    if (authState.state.id) {
    } else {
      router.push('/status/cancel')
    }
  }
  const orderTrackingDetails = async (id: number) => {
    setDataLoaded(true)
    await OrderService.orderTrackingId(id).then(async (res) => {
      const result = await res.json()
      setTrackingStatusMsg(result)
      if (result.status) {
        setTrackingIdPopup(true)
        setShippingDetails(result.shippingDetails)
        setTrackingDetails(result.shippingDetails?.trackingDetails)
        setDataLoaded(false)
      } else {
        setTrackingIdPopup(false)
        notify.appNotification(result.msg, 'ERROR')
        setDataLoaded(false)
      }
    })
  }

  return {
    invoice,
    setInvoice,
    orderItemData,
    cancelledOrderDetailsData,
    setOrderProductItemDetails,
    orderProductItemDetails,
    status,
    setStatus,
    rating,
    setRating,
    breadCrub,
    expanded,
    setExpanded,
    setOtherProduct,
    otherProduct,
    setOtherProductList,
    otherProductList,
    setOrderProductPolicy,
    orderProductPolicy,
    cancelledOrdersTotalData,
    setCancelledOrdersTotalData,
    cancelledResponseDatas,
    setCancelledResponseDatas,
    orderSummary,
    setOrderSummary,
    orderdetailshow,
    setOrderdetailshow,
    placedOrdersData,
    thankScreen,
    thankScreenProduct,
    tcModel,
    setTcModel,
    newPost,
    postCancel,
    updateCancelReason,
    cancelPost,
    setCancelPost,
    orderhistory,
    orderstepper,
    cancelType,
    orderTrackingDetails,
    setShippingDetails,
    shippingDetails,
    trackingDetails,
    setDataLoaded,
    dataLoaded,
    setTrackingStatusMsg,
    trackingStatusMsg,
    setTrackingIdPopup,
    trackingIdPopup,
  }
}

export const OrderDetailState: any = createContainer(OrderDetail)
