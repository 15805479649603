import { Config } from '~/config/config'
import { HttpClient, createFetch } from './htttp'
import Cookies from 'js-cookie'

interface ConfirmOrderProps {
  address_id: number
  billing_address_id: number
  payment_method: string
  products:
    | {
        content: {
          mode: string
          charge: number
          pickup: string
        }
        product_id: number
        item_code: string
      }[]
    | any
  sub_total?: number
  coupon_id?: number
  payment_type?: string
  total?: number
  code?: any
}

export class OrderService {
  public static async orderTracking(id: string) {
    return HttpClient.get(`/api/base/orderTracking/${id}`)
  }

  public static async confirmOrder(data: ConfirmOrderProps) {
    return HttpClient.post('/crm/saveorder', data, {
      headers: {
        channel: 'ONLINE',
      },
    })
  }

  public static async orderProducts(id: any) {
    return HttpClient.get(`/api/base/orderProducts/processOrder/${id}`)
  }

  public static async orderProductDetails(data: {
    order_id: number
    product_id: number
    item_code: any
    order_product_id: any
  }) {
    let setToken: any = Config.TOKEN_NAME
    const token = Cookies.getJSON(setToken)
    const header = {
      Authorization: `Bearer ${String(token).replace('%22', '')}`,
    }
    const datas = createFetch(`/oms/myorder/orderproduct`, 'POST', header, data)
    return datas
  }

  // public static async orderProductsComplete(id: any) {
  //   return HttpClient.get(`/api/base/orderProducts/completeOrder/${id}`)
  // }

  public static async cancelledOrderDetails(id: any, type: any) {
    let setToken: any = Config.TOKEN_NAME
    const token = Cookies.getJSON(setToken)
    const header = {
      Authorization: `Bearer ${String(token).replace('%22', '')}`,
    }
    const datas = createFetch(
      `/oms/cancelorder/datas?order_id=${id}&type=${type}`,
      'GET',
      header
    )
    return datas
  }

  public static async thankScreen(id: any) {
    return HttpClient.get(`/crm/thankscreen/${id}`, {})
  }

  public static async orderProductss(
    page: number,
    body: { order_type: any; order_timing: any }
  ) {
    let setToken: any = Config.TOKEN_NAME
    const token = Cookies.getJSON(setToken)
    const header = {
      Authorization: `Bearer ${String(token).replace('%22', '')}`,
    }
    const datas = createFetch(
      `/oms/myorder/allorder/${page}`,
      'POST',
      header,
      body
    )
    return datas
  }
  public static async Invoice(body: { order_id: any; order_product_id: any }) {
    let setToken: any = Config.TOKEN_NAME
    const token = Cookies.getJSON(setToken)
    const header = {
      Authorization: `Bearer ${String(token).replace('%22', '')}`,
    }
    const datas = createFetch(
      `/oms/myorder/Download/Invoice`,
      'POST',
      header,
      body
    )
    return datas
  }

  // public static async orderDetail(id: string) {
  //   return HttpClient.get(`/api/base/orderProducts/allOrder/${id}`)
  // }

  public static async cancelOrders(data: any) {
    let setToken: any = Config.TOKEN_NAME
    const token = Cookies.getJSON(setToken)
    const header = {
      Authorization: `Bearer ${String(token).replace('%22', '')}`,
    }
    const datas = createFetch(`/oms/cancelorder`, 'POST', header, data)
    return datas
  }
  public static async orderTrackingId(id: number) {
    let setToken: any = Config.TOKEN_NAME
    const token = Cookies.getJSON(setToken)
    const header = {
      Authorization: `Bearer ${String(token).replace('%22', '')}`,
    }
    const datas = createFetch(`/oms/myorder/Awbtracking`, 'POST', header, id)
    return datas
  }
}
