import axios from 'axios'
import { config } from 'process'
import { Config } from '~/config/config'
import { Channel } from '~/types/pim'
import { HttpClient, createFetch } from './htttp'

export class PimHomeService {
  public static async PimPage(pageCode: string) {
    return HttpClient.get(`pim/page?code=${pageCode}`, {
      params: {
        channel: 'ONLINE',
        locale: 'en-IN',
      },
    })
  }

  // using axios
  public static async PimPageUsingAxios(
    pageCode: string,
    channel: Channel,
    locale: string
  ) {
    const header = {
      channel: channel,
      locale: locale,
    }
    const data = createFetch(`/pim/page?code=${pageCode}`, 'GET', header)
    return data

    // return fetch(`${Config.BASE_URL}/pim/page?code=${pageCode}`, {
    //   method: 'GET',
    //   headers: {
    //     channel: channel,
    //     locale: locale,
    //   },
    // })
  }

  public static async groupType(
    type: string,
    code: string,
    channel: Channel.ONLINE = Channel.ONLINE,
    locale: string = 'en-IN'
  ) {
    return HttpClient.get(`pim/page/groups`, {
      params: {
        code: code,
        type: type,
      },
      headers: {
        channel: channel,
        locale,
      },
    })
  }

  public static async shape(
    code: string,
    type: string,
    shape: string,
    channel: Channel.ONLINE = Channel.ONLINE,
    locale: string = 'en-IN'
  ) {
    return HttpClient.post(
      `pim/page/shape`,
      {
        code,
        type,
      },
      {
        headers: {
          channel,
          locale,
        },
      }
    )
  }

  public static async PimCentral(params: {
    channel: string
    item_code: number
  }) {
    const header = {
      Authorization: `Bearer ${Config.STACK_STATUS_TOKEN}`,
      channel: `${params.channel}`,
    }
    const data = createFetch(
      `/ci/v1/deliverytype/stock?item_code=${params.item_code}`,
      'GET',
      header
    )
    return data
  }
  public static async PimDelivery(params: {
    pincode: number
    channel: string
    item_code: string
  }) {
    return HttpClient.get(`/ci/v1/deliverytype/pincode`, {
      params: {
        pincode: params.pincode,
        item_code: params.item_code,
      },
      headers: {
        Authorization: `Bearer ${Config.STACK_STATUS_TOKEN}`,
        channel: `${params.channel}`,
      },
    })
  }
  public static async PimDeliveryPickupstore(params: {
    pincode: number
    channel: string
    item_code: any
  }) {
    return HttpClient.get(
      `/ci/v1/deliverytype/pincode/address?pincode=${params.pincode}&item_code=${params.item_code}`,
      {
        headers: {
          Authorization: `Bearer ${Config.STACK_STATUS_TOKEN}`,
          channel: `${params.channel}`,
        },
      }
    )
  }
  public static async Ippincode() {
    return HttpClient.get(`/ip`, {
      withCredentials: false,
    })
  }
  public static async PimMenuNavigation({ channel }) {
    const header = {
      channel: channel,
      locale: 'en-IN',
    }
    const data = createFetch(`/pim/navigation`, 'GET', header)
    return data
  }
}
