import { Button } from '../button/button'
import { useEffect, useState } from 'react'
import { Model } from '../model/model'
import { AuthState } from '~/stores/auth'
import React from 'react'
import { TextInput } from '~/components/ui/text-input/text-input'
import style from './sharelocation.module.scss'
import { CloseNew } from '../icons/close_new'
import { Logger } from '~/util'
import Img from '../img/img'
export default function ShareLocation({
  LocationShare,
  setLocationShare,
  validPincode,
}: any) {
  const [pincode, setPincode] = useState('')
  const [loading, setLoading] = useState(false)
  const auth = AuthState.useContainer()
  useEffect(() => {
    const pin: any = localStorage.getItem('myData')
    setPincode(pin)
    init()
    return () => {}
  }, [])
  const init = async () => {
    try {
      let sharedata: any = localStorage.getItem('pinCode')
      const local: any = JSON.parse(sharedata)
      if (local) {
        if (local.pinCode) {
          auth.updatePinCode(String(local.pinCode))
        }
      }
    } catch (err) {}
  }
  const formSubmit = () => {
    setLoading(true)
    Logger.log(
      auth.pinCode,
      pincode != '' || pincode != null,
      String(auth.pinCode).length == 6
    )
    if (!(pincode == '' || pincode == null)) {
      auth.updatePinCode(pincode)
      setLoading(false)
      localStorage.setItem('myData', pincode)
    }
    // if (/^\d+$/.test(pincode) && String(pincode).length == 6) {
    //   setLocationShare(!LocationShare)
    // }
  }

  return (
    <Model
      model={LocationShare}
      className={`${style.custom_model}`}
      closeModel={() => setLocationShare(!LocationShare)}
    >
      <div
        className={`${style.shareclose}`}
        onClick={() => setLocationShare(!LocationShare)}
      >
        <CloseNew className={`${style.closebutton}`} />
      </div>
      <form
        className="border border-gray-200"
        onSubmit={(e) => {
          e.preventDefault()
          formSubmit()
        }}
      >
        <div>
          <img
            className="w-full"
            src="https://img.poorvika.com//pincode_request.jpg"
            // loader={() => `https://img.poorvika.com//pincode_request.jpg`}
            // blurDataURL={`https://img.poorvika.com//pincode_request.jpg`}
            //placeholder="blur"
            alt="locationshare"
          />
        </div>
        <div className={`${style.share_text} p-6 text-center mb-2`}>
          <h4 className="font-bold p-4 text-2xl ">
            Help us to serve you better
          </h4>
          <p className="text-sm leading-6 text-gray-500 mb-5 w-5/6 mx-auto">
            Enter your delivery pincode to check nearest stores,product
            availability & faster delivery options
          </p>
          <div className={` flex items-center`}>
            <TextInput
              className={`${style.share_input}`}
              id="pinCode"
              placeHolder="Enter Pincode"
              value={`${/^\d+$/.test(pincode) ? pincode : ''}`}
              onChange={setPincode}
              maxLength={6}
            />
            <Button
              // onClick={formSubmit}
              type="submit"
              className={style.location_form__content__btn}
              variant={'NORMAL'}
              spinnerWidth={3}
              spinnerThickness={3}
              loading={loading}
              disabled={pincode?.length > 5 ? false : true}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Model>
  )
}
