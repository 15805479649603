import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'
import { AdldEW } from '~/components/pages/product-detail/adld/adld'
import { EWPlan } from '~/components/pages/product-detail/adld/adld-list-item/adld-list-item'
import { CartService } from '~/services/cart.service'
import { CHANNEl, ProductService } from '~/services/product.service'
import { ReviewService } from '~/services/review.service'
import { Logger } from '~/util'
import { AuthState } from './auth'
import { RecentlyViewedState } from './recently-viewed'
import { UseNotificationState } from '~/stores/notify'
import { Channel } from '~/types/pim'
import { PimHomeService } from '~/services/pim.service'
import Cookies from 'js-cookie'

interface RootObject {
  preorder_endDate: string | number | Date
  preorder_startDate: string | number | Date
  slug: any
  id: number
  image: string
  color: string
  storage: string
  item_code: string
  storage_id: number
  option_value: string
  breadcrumbs: Breadcrumb[]
  option_value_array: any[]
  emi: Emi
  offers: Offer[]
  discount: number
  related_products: Relatedproduct[]
  exchange: Exchange
  wishlist: boolean
  name: string
  manufacturer: string
  model: string
  additional_image_color: Additionalimagecolor[]
  keyspecs: Keyspec[]
  meta_title: string
  meta_description: string
  meta_keyword: string
  price: number
  price_formatted: string
  rating: number
  description: string
  attributeGroups: Attributegroup[]
  special: number
  special_formatted: string
  availability_stock: string
  quantity: number
  videos: any[]
  reviews: Reviews
  reviews_v1: any
  weburl: string
  product_option: any[]
  product_option_value: any[]
  pre_order: boolean
  preorder_button: string
  preorder_amount: string
  preorder_message: string
  variants: Variant[]
  types: any
  variantsmeta: any[]
  category: any
  code: string
  prices: any
  erp_item_code: any
  specifications: any
  mrp: any
  starrating: any
}

export interface BoughtTogether {
  name: string
  brand: string
  cart_status: boolean
  default_product_status: boolean
  discount: number
  image: any
  item_code: string
  model: string
  option_value: string
  special: any
  price: any
  product_id: number
  rating: number
  slug: string
  preorder_amount: number
  prices: any
  mrp: any
}

export interface Variant {
  slug: string
  color: string
  storage: string
  image: string
}

// interface Reviewsv1 {
// one_star: number
// two_star: number
// three_star: number
// four_star: number
// five_star: number
// review_total: number
// reviews: Review[]
// }

interface Reviews {
  review_total: number
  reviews: Review[]
}

interface Review {
  author: string
  title: string
  text: string
  rating: number
  date_added: string
}

interface Attributegroup {
  code: number
  name: string
  attributes: Attribute[]
}

interface Attribute {
  code: string
  name: string
  type: string
  value: string
}

interface Keyspec {
  keyspecification: string
}

interface Additionalimagecolor {
  image: string
  product_image_id: number
  product_id: number
  slug: string
}

interface Exchange {
  url: string
  icon: string
  status: boolean
}

interface Relatedproduct {
  name: string
  value: any[]
}

export interface Offer {
  _id: string
  offers_type: string
  category: string
  sub_category: string
  brand_name: string
  model_name: string
  item_code: string
  sku_code: string
  bank_name: string
  payment_type: string
  min_txn: string
  max_txn: string
  max_cb: string
  percentage: string
  flat_cb: string
  poorvika_funding: string
  offer_text: string
  t_c: string
  valid_on: string
  valid_at: string
  start_date: string
  start_time: string
  end_date: string
  end_time: string
  image_icon: string
  button_type: string
  image_url: string
  section_type: string
  payment_methods: string
  created_at: string
  updated_at: string
  __v: number
}

interface Emi {
  ISSUER?: any
  RESPONSE_CODE: string
  RESPONSE_MESSAGE: string
}

interface Breadcrumb {
  category_id: number
  name: string
}

const DetailState = (pimInitialData = {}) => {
  const authState = AuthState.useContainer()
  const [data, setData] = useState(pimInitialData as RootObject)
  const router = useRouter()
  const [breadCrub, setBreadCrub] = useState([] as any[])
  const [colors, setColors] = useState([] as Variant[])
  const [storages, setStorages] = useState([] as Variant[])
  const recentlyViewedState = RecentlyViewedState.useContainer()
  const [relatedProducts, setRelatedProducts] = useState([] as Variant[])
  const [offersTotalData, setOffersTotalData] = useState([] as Variant[])
  const [availability_stock, setAvailability_stock] = useState(0)
  const [scrollElement, setScrollElement] = useState(0)
  const [boughtTogetherTotalData, setBoughtTogetherTotalData] = useState(
    [] as BoughtTogether[]
  )
  const [nbfcData, setNbfcData] = useState([])
  const [effectivePriceData, setEffectivePriceData] = useState([])
  const [paymentOffers, setPaymentOffers] = useState<any>([])
  const [exchangeOffers, setExchangeOffers] = useState<any>([])
  const [additionalOffers, setAdditionalOffers] = useState<any>([])
  const [freeOffers, setFreeOffers] = useState<any>([])
  const [otherOffers, setOtherOffers] = useState<any>([])
  const [nbfcMinAmout, setNbfcMinAmout] = useState<any>({})
  const [ratingCount, setRatingCount] = useState(0)
  const [limit, setLimit] = useState(3)
  const [page, _setPage] = useState(1)
  const [sort, _setSort] = useState('DESC')
  const [filter, setFilter] = useState('')
  const [reviewTotalData, setReviewTotalData] = useState([])
  const [overiewTotalData, setOveriewTotalData] = useState([])
  const [videosGroup, setVideosGroup] = useState([])
  const [overviewLoader, setOverviewLoader] = useState(false)
  const notify = UseNotificationState.useContainer()
  const [newNotifyDetails, setNewNotifyDetails] = useState({
    name: '',
    email: '',
    mobile_number: '',
    sms_notify: true,
    whatsapp_notify: true,
    email_notify: true,
  })
  const [pimMode, setPimMode] = useState('')
  const [listItems, setListItems] = useState(false)
  const [removeCount, setRemoveCount] = useState(0)
  const [showPreorder, setShowPreorder] = useState(false)
  const updateNotifyDetails = (key: string, value: any) => {
    setNewNotifyDetails({ ...newNotifyDetails, [key]: value })
  }
  useEffect(() => {
    if (!!data) {
      if (!!data.category?.ancestors) {
        initBreadCrub(data)
      }
    }
  }, [notify.storeName])

  const channel = useMemo(() => {
    return !!Cookies.get('store') ? CHANNEl.SHOP : CHANNEl.ONLINE
  }, [router.pathname])

  const initData = (data: any, mode: string) => {
    let videosList = []
    if (!!data.videogroup) {
      data.videogroup.forEach((element) => {
        if (!!element.list) {
          element.list.forEach((ele) => videosList.push(ele))
        }
      })
    }
    setPimMode(mode)
    setData(data)
    setVideosGroup(videosList)
    recentlyViewedState.addItem(data.slug, data.erp_item_code)
    initBreadCrub(data)
    offerSortDataPim(data.erp_item_code)
    effectiveOfferData(data.erp_item_code, data)
    getProductNbfcDataShow(data.erp_item_code)
    boughtTogetherData(data.erp_item_code)
    if (!!data.storage) {
      updateStorages(data)
    }
    getAdld(data)
    getProductPoliciesData(data)
    compareProductsList(data.erp_item_code, data)
    //reviewProductId(data.id)
    // getAdld(data)
    // compareProductsList(data.id, data.item_code, data)
    // //reviewProductId(data.id)
    // productPolicyItems(data.id)
    updatePinCode(data).then((p) => {
      Logger.log('Pin', Number(p))
    })
    let sharedata: any = localStorage.getItem('pinCode')
    const local: any = JSON.parse(sharedata)
    updateStockStatus({
      item_code: data.erp_item_code,
      channel: channel,
    })
  }
  useEffect(() => {
    if (data.pre_order == true) {
      const currentdate = new Date()
      const startDate = new Date(data.preorder_startDate)
      const endDate: any = new Date(data.preorder_endDate)
      if (currentdate.valueOf() >= startDate.valueOf()) {
        if (currentdate.valueOf() <= endDate?.valueOf()) {
          setShowPreorder(true)
        } else {
          setShowPreorder(false)
        }
      }
    }
  }),
    [data]
  useEffect(() => {
    if (authState.state.id != '' && data.slug != undefined) {
      recentlyViewedState.onInit()
    } else if (authState.state.id == '' && data.slug != undefined) {
      recentlyViewedState.onInit()
    }
  }, [authState.state.id, data.slug])

  const [stockStatus, setStockStatus] = useState(null)
  const [statusLoading, setStatusLoading] = useState(true)
  const updateStockStatus = async (props: { item_code: any; channel: any }) => {
    setStatusLoading(false)
    try {
      const data = await PimHomeService.PimCentral({
        item_code: props.item_code,
        channel: props.channel,
      })
      const result = await data.json()
      setStockStatus(result.data)
      setStatusLoading(true)
    } catch (err) {
      Logger.error(err)
      setStatusLoading(true)
    }
  }

  const updatePinCode = async (data: RootObject) => {
    let pinC = localStorage.getItem('pinCode')
    if (!!pinC) {
      let pc = JSON.parse(pinC)
      if (!!pc) {
        if (typeof pc['pinCode'] != 'undefined') {
          if (String(pc['pinCode']).length == 6) {
            authState.setPinCode(pc['pinCode'])
            //getProductDeliveryStatus(pc['pinCode'], data).catch(Logger.error)
          }
          return pc['pinCode']
        } else {
          authState.setPinCode('')
          return ''
        }
      }
    }
    localStorage.setItem('pinCode', JSON.stringify({ pinCode: '' }))
    authState.setPinCode('')
    return ''
  }
  const categoryRedirectLink = (item) => {
    const categoryPageLink = {
      pathname: `/s`,
      query: {
        categories: `categories:[\`${item.name}\`]`,
      },
    }
    // if (!!notify.storeName) {
    // categoryPageLink.query['store'] = notify.storeName
    // }
    return categoryPageLink
  }
  const initBreadCrub = (data: RootObject) => {
    var breadData: any = data.category?.ancestors.map((item: any) => ({
      label: item.name,
      link: categoryRedirectLink(item),
    }))
    breadData.push({
      label: data.category.name,
      link: categoryRedirectLink(data.category),
    })
    if (!!notify.storeName) {
      breadData.push({
        label: `${String(router.query.slug).replace(/-/gi, ' ')}`,
        link: {
          pathname: `/${router.query.slug}/p`,
          // query: { store: notify.storeName },
        },
      })
    } else {
      breadData.push({
        label: data.name,
        link: { pathname: `/${data.code}/p`, query: {} },
      })
    }

    setBreadCrub([
      {
        label: 'Home',
        link: { pathname: !!notify.storeName ? '/s' : '/', query: {} },
      },
      ...breadData,
    ])
  }

  const updateColors = (data: RootObject) => {
    setColors([])
    let newCol: Variant[] = []
    for (const e of data.variants) {
      if (e.storage == data.storage) {
        if (!!!newCol.find((el) => el.color == e.color)) {
          newCol.push(e)
        }
      }
    }
    setColors(newCol)
  }

  const updateStorages = (data: RootObject) => {
    setStorages([])
    let newSto: Variant[] = []
    for (const e of data.variants) {
      if (e.storage != data.storage) {
        if (!!!newSto.filter((el) => el.storage == e.storage).length) {
          newSto.push(e)
        }
      }
      if (e.slug == router.query.slug) {
        newSto.push(e)
      }
    }
    setStorages(newSto)
    updateColors(data)
  }

  const [deliveryStatus, setDeliveryStatus] = useState(
    [] as {
      mode: string
      icon: string
      delivery_time: string
    }[]
  )

  const getProductDeliveryStatus = async (
    pinCode: number,
    data: RootObject
  ) => {
    try {
      if (!!data) {
        if (!!String(data.erp_item_code).length) {
          localStorage.setItem('pinCode', JSON.stringify({ pinCode }))
          let channel = Cookies.get('store')
          const da = await PimHomeService.PimDelivery({
            item_code: data.erp_item_code,
            pincode: pinCode,
            channel: !!channel ? Channel.SHOP : Channel.ONLINE,
          })

          setDeliveryStatus(da.data)
          return da.data
        }
      }
      return ''
    } catch (err) {
      Logger.error(err)
      setDeliveryStatus([])
      return Promise.reject(err)
    }
  }
  const offerSortDataPim = async (product_id: any) => {
    try {
      const totalOffersData = await ProductService.getProductOffersPIM(
        product_id
      )
      if (totalOffersData.data.length) {
        totalOffersData.data.sort(
          (a: any, b: any) => a.section_type - b.section_type
        )
      }
      setOffersTotalData(totalOffersData.data)
      // setProductPolicyList(totalPolicyData.data.data)
      return totalOffersData.data
    } catch (err) {
      Logger.error(err)
    }
  }
  const effectiveOfferData = async (product_id: any, data: any) => {
    try {
      const effectiveTotalData = await ProductService.getProductEffectivePrice(
        product_id,
        ''
      )
      if (effectiveTotalData?.data?.data?.offerList?.length) {
        const offerGroups: any = {
          exchanges: [],
          payments: [],
          additionals: [],
          others: [],
          free: [],
        }

        effectiveTotalData.data.data.offerList?.forEach((item: any) => {
          item.checked = !(
            item.offerSelection === 'notselected' ||
            item.offerType === 'Instant Discount' ||
            item.offerType === 'Deferred Cashback'
          )
          item.showTc = false
          item.showBan = false
          item.showOffer = false
          item.sponsorName = item?.sponsor
            .map((spon: any) => spon.name)
            .join(', ')
          item.channelName = item?.channel
            .map((chan: any) => chan.name)
            .join(', ')

          switch (item.offerType) {
            case 'TradeIn and Exchange':
              if (
                item.checked &&
                item.exchangeBonus > 0 &&
                !!item.tradeValues
              ) {
                item.maxBenefites = item.exchangeBonus
                item.exchangeVal = item.exchangeBonus
                item.deviceVal = 0
              }
              offerGroups.exchanges.push(item)
              break
            case 'Instant Discount':
            case 'Deferred Cashback':
              offerGroups.payments.push(item)
              break
            case 'Offer Text':
            case 'Standard EMI':
              offerGroups.additionals.push(item)
              break
            default:
              offerGroups.others.push(item)
          }
        })

        offerGroups.exchanges.sort((a, b) => b.maxBenefites - a.maxBenefites)
        offerGroups.payments.sort((a, b) => b.maxBenefites - a.maxBenefites)
        offerGroups.additionals.sort((a, b) => b.maxBenefites - a.maxBenefites)
        offerGroups.others.sort((a, b) => b.maxBenefites - a.maxBenefites)

        let { exchanges, payments, additionals, others, free } = offerGroups
        let checkFlag = true
        payments = payments.map((element) => {
          if (
            checkFlag &&
            element?.offerSelection !== 'notselected' &&
            element.offerType !== 'Deferred Cashback'
          ) {
            element.checked = true
            checkFlag = false
          }
          return element
        })
        payments.push({
          offerName: 'Other Payments',
          sponsorName: 'Not Available',
          channelName: 'Not Available',
          maxBenefites: 0,
          checked: false,
          offerId: 'others',
        })

        const exclusiveOffers = {
          'Freebie Offer': null,
          'Combo Offer': null,
          'Sellout/Activation Offers': null,
        }

        others.forEach((offer: any) => {
          if (
            offer.exclusivity === 'Yes' &&
            exclusiveOffers.hasOwnProperty(offer.offerType)
          ) {
            if (
              !exclusiveOffers[offer.offerType] ||
              offer.maxBenefites > exclusiveOffers[offer.offerType].maxBenefites
            ) {
              exclusiveOffers[offer.offerType] = offer
            }
          }
        })

        // Uncheck non-exclusive offers
        others.forEach((offer: any) => {
          if (
            offer.exclusivity === 'Yes' &&
            exclusiveOffers.hasOwnProperty(offer.offerType)
          ) {
            if (offer.offerId !== exclusiveOffers[offer.offerType].offerId) {
              offer.checked = false
            }
          }
        })
        setExchangeOffers(exchanges)
        setPaymentOffers(payments)
        setAdditionalOffers(additionals)
        setOtherOffers(others)
        setFreeOffers(free)
        setEffectivePriceData(effectiveTotalData?.data?.data?.offerList)
      }
      return effectiveTotalData.data
    } catch (err) {
      Logger.error(err)
    }
  }

  const getProductNbfcDataShow = async (product_id: any) => {
    try {
      const productNbfcData = await ProductService.getProductNbfcData(
        product_id
      )
      setNbfcData(productNbfcData.data.data.financeModelList)
      return productNbfcData.data.data
    } catch (err) {
      Logger.error(err)
    }
  }
  const offerSortData = useMemo(() => {
    return !!data.offers
      ? data.offers.sort((a: any, b: any) => a.section_type - b.section_type)
      : []
  }, [data])

  const addToCart = async (prop?: { product_id: any; item_code: any }) => {
    try {
      const payload: any = {
        product_id: data.id,
        item_code: data.item_code,
        quantity: 1,
      }
      if (prop) {
        payload.item_code = prop.item_code
        payload.product_id = prop.product_id
      }
      const da = await CartService.addItem(payload)
      return da
    } catch (err) {
      return Promise.reject(err)
    }
  }

  const [ewPlan, setEwPlan] = useState([] as EWPlan[])
  const [adldList, setAdldList] = useState([] as AdldEW[])

  const getAdld = async (dat: RootObject) => {
    try {
      setAdldList([])
      const da = await ProductService.getAdld(dat.erp_item_code)
      let totalAdldData = da.data.data.addons
      const unique2 = totalAdldData.reduce((acc, current) => {
        const x = acc.find((item) => item.type === current.type)
        if (!x) {
          return acc.concat([current])
        } else {
          return acc
        }
      }, [])
      let result = []
      unique2.map((e, i) => {
        let sss = {
          name:
            e.type.toUpperCase() == 'INSURANCE'
              ? 'Accidental and Liquid Damage Protection Plan'
              : e.type == 'WARRANTY' && 'Extended Warranty',
          one_assist_status: i,
          checked: e.checked,
          active_plan_name: '',
          active_plan_price: '',
          active_plan_code: 0,
          active_apx_itemcode: 0,
          plan: [],
        }
        totalAdldData.forEach((data) => {
          if (data.type == e.type) {
            sss.plan.push({
              plan_code: data.planCode,
              apx_itemcode: data.itemCode,
              type: data.type,
              brand: data.brand,
              plan_name: data.description,
              poorvika_mrp: !!data.planPrice
                ? data.planPrice
                : data.planMrpPrice,
              maxPrice: data.planMrpPrice,
              sellingPrice: data.planPrice,
              tc: data.terms_condition,
              isActive: data.isActive,
            })
          }
        })
        result.push(sss)
      })
      setAdldList(result)
      return
    } catch (err) {
      Logger.error(err)
    }
  }
  const getProductPoliciesData = async (dat: RootObject) => {
    try {
      const da = await ProductService.getProductPolicies(dat.erp_item_code)
      const result = await da.json()
      if (result.data.addons) {
        setProductPolicyList(result.data.addons)
      }
      //setAdldList(result)
      return
    } catch (err) {
      Logger.error(err)
    }
  }

  const [allCompareList, _setAllCompareList] = useState([])
  const [productPolicyList, setProductPolicyList] = useState([])
  const [compareList, setCompareList] = useState([] as any)

  const boughtTogetherData = async (product_id: any) => {
    try {
      const totalBoughtTogetherData = await ProductService.getProductBoughtTogetherPIM(
        product_id
      )
      let boughtTogetherResult = []
      const totalBoughtTogether = await totalBoughtTogetherData.json()
      if (totalBoughtTogether.data.boughttogether.length) {
        totalBoughtTogether.data.boughttogether.forEach((item, _i) => {
          let newItem = {
            brand: item.brand,
            cart_status: item.cart_status,
            default_product_status: item.default_product_status,
            discount: item.discount,
            image: item.image?.url,
            item_code: item.erp_item_code,
            model: item.model,
            name: item.name,
            option_value: item.option_value,
            price: Number(item.mrp?.price),
            price_format: item.mrp?.price,
            product_id: item.product_id,
            rating: item.rating,
            slug: item.code,
            special: Number(item.prices?.sp),
            special_format: item.prices?.sp,
            preorder: item.mrp?.preorder_amount,
            preorder_amount: Number(item.mrp?.preorder_amount),
            prices: item.prices,
            mrp: item.mrp,
          }
          boughtTogetherResult.push(newItem)
        })
      }
      setBoughtTogetherTotalData(boughtTogetherResult)
      return totalBoughtTogether.data.boughttogether
    } catch (err) {
      Logger.error(err)
    }
  }
  // const reviewProductId = async(id:any)=>{
  // try {
  // const totaldata = await ReviewService.getRatings(limit,page,id,sort,filter)
  // setReviewTotalData(totaldata.data.data)
  // return totaldata.data.data
  // } catch (err) {
  // Logger.error(err)
  // }
  // }
  const compareProductsList = async (itemCode: any, data: RootObject) => {
    try {
      const da = await ProductService.relatedProducts(itemCode)

      const current = {
        specifications: data.specifications,
        name: data.name,
        prices: data.prices,
        rating: data.starrating,
        // review_count: data.reviews_v1.review_total,
        image: data.image,
        erp_item_code: itemCode,
        mrp: data.mrp,
        viewing: 'You are viewing :',
        discount: data.discount,
        code: data.code,
      }
      const emptyData = {
        specifications: [],
        name: '',
        prices: '',
        rating: 0,
        image: '',
        erp_item_code: '',
        mrp: '',
        discount: '',
        code: '',
      }
      const compareFirstItem = [
        current,
        ...da.data.data.relatedproducts.slice(0, 3),
      ]
      if (da.data.data.relatedproducts.length < 3) {
        const count = 3 - da.data.data.relatedproducts.length
        for (let i = 0; i < count; i++) {
          compareFirstItem.push(emptyData)
        }
      }
      _setAllCompareList(da.data.data.relatedproducts)
      setRelatedProducts(da.data.data.relatedproducts)
      setCompareList(compareFirstItem)
      return da.data
    } catch (err) {
      Logger.error(err)
    }
  }

  const compareDropdown = useMemo(() => {
    const ids = compareList.map((ele: any) => ele.erp_item_code)
    return allCompareList.filter((ele: any) => !ids.includes(ele.erp_item_code))
  }, [compareList])

  const offers = useMemo(() => {
    if (!!data.offers) {
      const offerSortData = data.offers.sort(
        (a: any, b: any) => a.section_type - b.section_type
      )
      return offerSortData
    }
    return []
  }, [])
  const overviewScroll = async () => {
    setOverviewLoader(true)
    const store = Cookies.get('store')
    try {
      await ProductService.getProductDetailPIM(
        router.query.slug,
        !!store ? CHANNEl.SHOP : CHANNEl.ONLINE,
        router.locale,
        1
      ).then((res) => {
        if (!!res) {
          setOverviewLoader(false)
          setOveriewTotalData(res.data.product)
          return res
        } else {
          setOverviewLoader(false)
        }
      })
    } catch (err) {
      setOverviewLoader(false)
      Logger.error(err)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  useEffect(() => {
    if (scrollElement == 1) {
      overviewScroll()
    }
    return () => window.removeEventListener('scroll', handleScroll)
  }, [scrollElement])

  const isBottom = (el) => {
    return el?.getBoundingClientRect().bottom <= window.innerHeight
  }
  const handleScroll = () => {
    const wrappedElement = document.getElementById('maindetail')
    if (isBottom(wrappedElement)) {
      setScrollElement(1)
      setListItems(true)
      document.removeEventListener('scroll', handleScroll)
    } else {
      document.removeEventListener('scroll', handleScroll)
    }
  }
  const [boughtTogether, setBoughtTogether] = useState([] as BoughtTogether[])

  const resetAdldBoughtTogather = () => {
    setAdldList([])
    setBoughtTogether([])
    setEwPlan([])
  }

  return {
    resetAdldBoughtTogather,
    boughtTogether,
    setBoughtTogather: setBoughtTogether,
    adldList,
    offers,
    setCompareList,
    initData,
    compareDropdown,
    data,
    breadCrub,
    offerSortData,
    colors,
    storages,
    // addToCart,
    ewPlan,
    setEwPlan,
    getProductDeliveryStatus,
    deliveryStatus,
    availability: data.availability_stock,
    allCompareList,
    compareList,
    compareProductsList,
    relatedProducts,
    productPolicyList,
    setProductPolicyList,
    //reviewProductId,
    reviewTotalData,
    setReviewTotalData,
    updateNotifyDetails,
    newNotifyDetails,
    setOffersTotalData,
    offersTotalData,
    boughtTogetherTotalData,
    ratingCount,
    setRatingCount,
    availability_stock,
    setAvailability_stock,
    statusLoading,
    stockStatus,
    getProductNbfcDataShow,
    setNbfcData,
    nbfcData,
    setPimMode,
    pimMode,
    setListItems,
    listItems,
    videosGroup,
    setVideosGroup,
    setOveriewTotalData,
    overiewTotalData,
    overviewScroll,
    overviewLoader,
    setOverviewLoader,
    setScrollElement,
    setRemoveCount,
    removeCount,
    paymentOffers,
    setPaymentOffers,
    exchangeOffers,
    setExchangeOffers,
    additionalOffers,
    setAdditionalOffers,
    freeOffers,
    setFreeOffers,
    otherOffers,
    setOtherOffers,
    setEffectivePriceData,
    effectivePriceData,
    showPreorder,
    setShowPreorder,
  }
}

export const DetailStore: any = createContainer(DetailState)
