import { useEffect, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'
import { CartService } from '~/services/cart.service'
import { AddressService } from '~/services/address.service'
import { UserService } from '~/services/user.service'
import { AuthState } from './auth'
import { Logger, PriceFormat } from '~/util'
import { UseNotificationState } from './notify'
import { CommonService } from '~/services/common.service'
import { OrderService } from '~/services/order.service'
import { Config } from '~/config/config'
import { ProductService } from '~/services/product.service'
import { StoreService } from '~/services/stores.service'
import { PimHomeService } from '~/services/pim.service'
import { Channel } from '~/types/pim'
import Cookies from 'js-cookie'
import { mrpPrice, sellingPrice } from '~/util/price'
export enum AddressFields {
  name = 'name',
  mobilenumber = 'mobilenumber',
  email = 'email',
  address_1 = 'address_1',
  address_2 = 'address_2',
  city = 'city',
  postcode = 'postcode',
  country_id = 'country_id',
  address_type = 'address_type',
  country = 'country',
  state = 'state',
  area = 'area',
  gst_number = 'gst_number',
  company = 'company',
  gst_billing_address = 'gst_billing_address',
}

export interface AddressType {
  name: string
  mobilenumber: string
  address_1: string
  address_2: string
  city: string
  postcode: string
  country_id: string
  address_type: string
  country: string
  state: string
  area: string
  gst_number: string
  company: string
  gst_billing_address: string
}

const Cart = () => {
  const authState = AuthState.useContainer()
  const notify = UseNotificationState.useContainer()

  const [formType, setFormType] = useState('NULL' as 'NEW' | 'EDIT' | 'NULL')
  const [loader, setLoader] = useState(false)
  const [loaderTxt, setLoaderTxt] = useState(false)
  const [items, setItem] = useState([] as any)
  const [totalEmpDetails, setTotalEmpDetails] = useState([] as any)
  const [verifyEmpReferer, setVerifyEmpReferer] = useState(false)
  const [notifyMeModel, setNotifyMeModel] = useState(false)
  useEffect(() => {
    if (authState.state.id) {
      getUserAddresses()
    }
    initialData()
  }, [authState.state.id])

  const initialData = async (param?: { removeID: any }) => {
    setLoader(true)
    try {
      const { data } = await CartService.getItems()
      data.data.forEach((item, i) => {
        if (item.adld.length) {
          item.adld.forEach((ele) => {
            ;(ele['plan_code'] = ele.planCode),
              (ele['plan_name'] = ele.name),
              (ele['poorvika_mrp'] =
                ele.planPrice < ele.maxPrice ? ele.planPrice : ele.maxPrice),
              (ele['apx_itemcode'] = ele.itemCode)
          })
          return
        }
      })

      if (data.data) {
        let result = data.data
        setItem(data.data)
        // setConfirmOrderListDupe(data.data)
        if (!!param) {
          if (!!param.removeID) {
            let filteredItem = [...result].filter(
              (v: any) => v.slug != param.removeID
            )
            setConfirmOrderList(filteredItem)
          }
        }
      }
      setLoader(false)
      return data.data
    } catch (err) {
      Logger.error(err)
      // alert(err)
      // setItem([]);
      // TODO: this should be removed after api works
      setItem([])
      setLoader(false)
    }
  }

  const getUserAddresses = async () =>
    AddressService.getManyAddress()
      .then(async (result) => {
        const res = await result.json()
        if (res.data.addresses.length) {
          setAddressList(res.data.addresses)
          let address = { ...res.data.addresses[0] }
          if (!!!address.email) {
            address['email'] = ''
          }
          setSelected(address)
          setBillingSelected({})
        } else {
          setAddressList([])
          setFormType('NEW')
        }
      })
      .catch(Logger.error)

  const [addressList, setAddressList] = useState([])
  const [address, setAddress] = useState({
    name: '',
    email: '',
    mobilenumber: '',
    address_1: '',
    address_2: '',
    city: '',
    postcode: '',
    country_id: '',
    address_type: '',
    country: '',
    state: '',
    area: '',
    gst_number: '',
    company: '',
    gst_billing_address: '',
  } as any)

  const filterAddressFields = (da: any) => {
    const obj: any = {}
    const fields = [
      'name',
      'mobilenumber',
      'email',
      'address_1',
      'address_2',
      'city',
      'postcode',
      'countryId',
      'address_type',
      'country',
      'state',
      'area',
      'gst_number',
      'company',
      'gst_billing_address',
    ]
    for (const key in da) {
      if (fields.includes(key)) {
        obj[key] = da[key]
      }
    }
    return obj
  }

  const [selected, setSelected] = useState({
    address_id: '',
    name: '',
    email: '',
    mobilenumber: '',
    address_1: '',
    address_2: '',
    city: '',
    postcode: '',
    country_id: '',
    address_type: '',
    country: '',
    state: '',
    area: '',
    gst_number: '',
    company: '',
    gst_billing_address: '',
  } as any)
  const [billingSelected, setBillingSelected] = useState({
    address_id: '',
    name: '',
    email: '',
    mobilenumber: '',
    address_1: '',
    address_2: '',
    city: '',
    postcode: '',
    country_id: '',
    address_type: '',
    country: '',
    state: '',
    area: '',
    gst_number: '',
    company: '',
    gst_billing_address: '',
  } as any)

  const buyNow = async (data: {
    product_id: number
    item_code: string
    quantity: 1
  }) =>
    await CartService.addItem(data)
      .then((_res) => initialData())
      .catch((err) => {
        notify.appNotification(`${err.response.data.error.message}`, 'ERROR')
        return Promise.reject(err)
      })

  const removeFromCart = async (id: any) => {
    setConfirmOrderListDupe(confirmOrderList)
    return await CartService.delete(id)
      .then((_res) => {
        let filteredItem = [...confirmOrderList].filter(
          (v: any) => v.slug != id
        )
        return initialData({ removeID: id })
          .then(() => {
            // setConfirmOrderList([]);
            setConfirmOrderList(filteredItem)
          })
          .then(() => setConfirmOrderListDupe([]))
      })
      .catch(Logger.error)
  }

  const addFromWhislist = async (data: {
    product_id: number
    item_code: string
  }) => {
    await UserService.addToWishList(data)
      .then((_res) => {
        initialData()
      })
      .catch(Logger.error)
  }

  const emptyAddress = () =>
    setAddress({
      name: '',
      mobilenumber: '',
      address_1: '',
      address_2: '',
      city: '',
      postcode: '',
      country_id: '',
      address_type: 'home',
      country: '',
      state: '',
      area: '',
      gst_number: '',
      company: '',
      gst_billing_address: '',
    })

  const updateAddressFields = (key: AddressFields, value: any) => {
    setAddress({ ...address, [key]: value })
  }
  const [receiptFields, setReceiptFields] = useState({
    date: new Date(),
    invoice: '',
    amount: '',
  })
  const updateReceiptFields = (key, val) => {
    setReceiptFields({
      ...receiptFields,
      [key]: val,
    })
  }
  const [postalDetail, setPostalDetail] = useState(
    [] as {
      Name: ''
      Description: null
      BranchType: ''
      DeliveryStatus: ''
      Circle: ''
      District: ''
      Division: ''
      Region: ''
      Block: ''
      State: ''
      Country: ''
      Pincode: ''
      Address: ''
    }[]
  )
  useEffect(() => {
    if (String(address.postcode).length == 6) {
      updateProductDetail()
    } else {
      setPostalDetail([])
    }
    return () => {}
  }, [address.postcode])
  const updateProductDetail = async () => {
    try {
      await AddressService.getAddressDetailWithPinCode(address.postcode)
        .then((res) => {
          const da = res.data.data
          if (da.length) {
            const arr = da[0].PostOffice
            setPostalDetail(arr)
            if (arr.length) {
              setTimeout(() => {
                setAddress({
                  ...address,
                  area: arr[0].Name,
                  city: arr[0].District,
                  state: arr[0].State,
                  country: arr[0].Country,
                })
                // setAddress({ ...address, countryId: data[0].Country })
              }, 500)
            }
          }
        })
        .catch(Logger.error)
      return
    } catch (err) {}
  }
  const addNewAddress = async () => {
    let addr: any = {
      ...address,
      gst_number: gstID,
      area: postalDetail[0]['Name'],
      city: postalDetail[0]['District'],
      state: postalDetail[0].State,
      country: postalDetail[0].Country,
      gst_billing_address: gstDetails,
    }
    Logger.log('LOcal', addr, postalDetail)

    await AddressService.addAddress(filterAddressFields(addr))
      .then(async (_res) => {
        notify.appNotification(_res.data.data.message, 'SUCCESS')
        setFormType('NULL')
        await getUserAddresses()
      })
      .catch(Logger.error)
  }
  const updateAddress = async () => {
    let addr = { ...address }
    let id: any = address['address_id']
    await AddressService.updateAddress(id, filterAddressFields(addr))
      .then((_res) => {
        Logger.log(addr)
        notify.appNotification(_res.data.data.message, 'SUCCESS')
        getUserAddresses()
      })
      .catch(Logger.error)
  }

  const cartItemTotal = () => {
    if (items.length) {
      let fo = 0
      if (items.length == 1) {
        let selling = sellingPrice(items[0]?.prices[0]?.sp)
        let mrp = mrpPrice(items[0]?.mrp[0]?.price)

        let qualityprice =
          items[0]?.pre_order == true
            ? items[0]?.preorderType == 'PERCENT_SALES_PRICE'
              ? selling.length != 0
                ? Math.round(
                    (selling[0]?.price / 100) * items[0]?.preorder_amount
                  )
                : Math.round((mrp[0]?.price / 100) * items[0]?.preorder_amount)
              : items[0]?.preorder_amount
            : selling?.length != 0
            ? selling[0]?.price * items[0]?.quantity
            : mrp[0]?.price * items[0]?.quantity
        fo = fo + qualityprice
      } else {
        items.map((itm) => {
          let selling = sellingPrice(itm?.prices[0]?.sp)
          let mrp = mrpPrice(itm?.mrp[0]?.price)

          let qualityprice =
            itm?.pre_order == true
              ? itm?.preorderType == 'PERCENT_SALES_PRICE'
                ? selling.length != 0
                  ? Math.round((selling[0]?.price / 100) * itm?.preorder_amount)
                  : Math.round((mrp[0]?.price / 100) * itm?.preorder_amount)
                : itm?.preorder_amount
              : selling?.length != 0
              ? selling[0]?.price * itm?.quantity
              : mrp[0]?.price * itm?.quantity
          fo = fo + qualityprice
        })
      }
      return PriceFormat(fo)
    }
    return PriceFormat(0)
  }
  // const pricelist = () => {
  //   if (items.length) {
  //     let qualityprice=0;
  //     if (items.length == 1) {
  //       let selling = sellingPrice(items[0]?.prices[0]?.sp)
  //         let mrp = mrpPrice(items[0]?.mrp[0]?.price)

  //         qualityprice = selling[0]?.price !="" ? selling[0]?.price * items[0]?.quantity : mrp[0]?.price * items[0]?.quantity
  //         //fo = fo + qualityprice;
  //     }
  //     items.map((itm)=>{
  //       let selling = sellingPrice(itm?.prices[0]?.sp)
  //       let mrp = mrpPrice(itm?.mrp[0]?.price)

  //       qualityprice = itm?.pre_order == true ? itm?.preorder_amount : selling[0]?.price !="" ? selling[0]?.price * itm?.quantity : mrp[0]?.price * itm?.quantity

  //     })
  //     return PriceFormat(qualityprice)
  //   }
  //   return PriceFormat(0)
  // }

  const updateQuantity = (id: any, qty: any, item_code: any, pincode: any) => {
    return CartService.updateQuantity(id, {
      quantity: qty,
      item_code: item_code,
      pincode: pincode,
    })
      .then((_res) => {
        return initialData()
      })
      .catch((err) => {
        notify.appNotification(`${err.response.data.error.message}`, 'WARNING')
        return
      })
  }

  const [gstID, setGstID] = useState('' as any)
  const [showGstDetails, setShowGstDetails] = useState(false)
  const [gstDetails, setGstDetails] = useState({
    stjCd: '',
    lgnm: '',
    stj: '',
    dty: '',
    adadr: [],
    cxdt: '',
    gstin: '',
    nba: [],
    lstupdt: '',
    rgdt: '',
    ctb: '',
    pradr: {
      addr: {
        bnm: '',
        st: '',
        loc: '',
        bno: '',
        stcd: '',
        dst: '',
        city: '',
        flno: '',
        lt: '',
        pncd: '',
        lg: '',
      },
      ntr: '',
    },
    sts: '',
    tradeNam: '',
    ctjCd: '',
    ctj: '',
  })
  const emptyGSTDetails = () => {
    setGstID('')
    setGstDetails({
      stjCd: '',
      lgnm: '',
      stj: '',
      dty: '',
      adadr: [],
      cxdt: '',
      gstin: '',
      nba: [],
      lstupdt: '',
      rgdt: '',
      ctb: '',
      pradr: {
        addr: {
          bnm: '',
          st: '',
          loc: '',
          bno: '',
          stcd: '',
          dst: '',
          city: '',
          flno: '',
          lt: '',
          pncd: '',
          lg: '',
        },
        ntr: '',
      },
      sts: '',
      tradeNam: '',
      ctjCd: '',
      ctj: '',
    })
    setShowGstDetails(false)
  }
  const gstAddress = useMemo(() => {
    const { st, bno, dst, pncd, stcd } = gstDetails.pradr.addr
    const address = `
${bno},
${st}
${dst}
${stcd} ${pncd}
    `
    return address
  }, [gstDetails])
  const updateGstDetail = (key: string, value: string) => {
    setGstDetails({ ...gstDetails, [key]: value })
  }
  const getGSTDetails = async () =>
    CommonService.gst(gstID)
      .then((_res) => {
        setGstDetails(_res.data.data.data)
        if (_res.data.data.data.gstin != '') {
          setShowGstDetails(true)
        } else {
          notify.appNotification(_res.data.error.message, 'ERROR')
        }
      })
      .catch((_err) => {})
  const [productOffer, setProductOffer] = useState([])
  const [productPaymentOptions, setProductPaymentOptions] = useState([])
  const [paymentMethodsLoader, setPaymentMethodsLoader] = useState(false)
  const getPaymentMethods = () => {
    const products = confirmOrderList.map((el: any) => ({
      slug: el.slug,
      product_id: el.product_id,
      item_code: el.item_code,
      quantity: el.quantity,
      content: {
        mode: el.deliveryOption.mode,
        pickup: !!el.pickup ? el.pickup : '',
      },
      adld: el.adld.map((e: any) => ({
        code: e.code,
        plan: {
          code: e.plan_code,
        },
      })),
    }))
    let totalList = {
      address_id: selected.address_id,
      billing_address_id: !!billingSelected.address_id
        ? billingSelected.address_id
        : selected.address_id,
      coupon: couponData ? couponData.couponCode : '',
      products,
      redirect_url: Config.PAYMENT_REDIRECT_URL,
      payment_gst_number: gstID,
    }
    setPaymentMethodsLoader(true)
    return CartService.getPaymentMethods(totalList)
      .then((_res) => {
        let rvObj = {
          card_type: '',
          code: 'rv',
          icon: '',
          id: 12,
          item_code: '',
          max_txn: '',
          min_txn: '',
          mode: 'web',
          name: 'RV',
          payment_client: 'rv',
          product_id: 0,
          status: '1',
        }
        let resData = _res.data.data.payments
        if (notify.isStore) {
          resData = [rvObj, ..._res.data.data.payments]
        }
        setProductPaymentOptions(resData)
        setPaymentMethodsLoader(false)
        setProductOffer(_res.data.data.products)
      })
      .catch(Logger.error)
  }

  const deleteAddress = (id: number) => {
    return AddressService.delete(id)
      .then(async (_res) => {
        return await getUserAddresses()
          .then((__res) => {
            notify.appNotification(_res.data.data.message, 'ERROR')
          })
          .catch(Logger.error)
      })
      .catch(Logger.error)
  }

  const [confirmOrderListDupe, setConfirmOrderListDupe] = useState([])
  const [confirmOrderList, setConfirmOrderList] = useState([])
  const [selectedPaymentType, setSelectedPaymentType] = useState(null as any)
  const moveToConfirm = async (data: any) => {
    setConfirmOrderList(data)
  }
  const refreshConfirmOrder = () => {
    const payload: any = (pre: any) => {
      // Logger.log('decr', pre)
      let output: any = pre.filter(
        (el: any) => !!items.filter((e: any) => e.slug == el.slug).length
      )

      output = output.map((e: any) => {
        const itm = items.filter((el: any) => el.slug == e.slug)
        if (!!itm.length) {
          const confirmOrderItem: any = pre.filter(
            (cel: any) => cel.slug == e.slug
          )
          return { ...confirmOrderItem[0], ...itm[0] }
        }
        return itm
      })
      // Logger.log('decr output', output)

      return output
    }
    setConfirmOrderList(payload)
  }
  const [dbOrderID, setDbOrderID] = useState('')
  useEffect(() => {
    if (!!!addressList.length) {
      setSelected({
        address_id: '',
        name: '',
        email: '',
        mobilenumber: '',
        address_1: '',
        address_2: '',
        city: '',
        postcode: '',
        country_id: '',
        address_type: '',
        country: '',
        state: '',
        area: '',
        gst_number: '',
        company: '',
        gst_billing_address: '',
      })
      setBillingSelected({
        address_id: '',
        name: '',
        email: '',
        mobilenumber: '',
        address_1: '',
        address_2: '',
        city: '',
        postcode: '',
        country_id: '',
        address_type: '',
        country: '',
        state: '',
        area: '',
        gst_number: '',
        company: '',
        gst_billing_address: '',
      })
    }
  }, [addressList.length])
  const [coupon, setCoupon] = useState('' as any)
  const [referer, setReferer] = useState('' as any)
  const [couponData, setCouponData] = useState(null as any)
  const saveOrders = async (_data: {
    total: number
    subTotal: number
    deliveryCharge: number
  }) => {
    const products = confirmOrderList.map((el: any) => ({
      product_id: el.product_id,
      item_code: el.item_code,
      quantity: el.quantity,
      slug: el.slug,
      content: {
        mode: el.deliveryOption.mode,
        pickup: !!el.pickup ? el.pickup : '',
      },
      adld: el.adld.map((e: any) => ({
        code: e.code,
        plan: {
          code: e.plan_code,
        },
      })),
    }))
    let totalList = {
      address_id: selected.address_id,
      billing_address_id: !!billingSelected.address_id
        ? billingSelected.address_id
        : selected.address_id,
      coupon: couponData ? couponData.couponCode : '',
      payment_method: selectedPaymentType.payment_client,
      code: selectedPaymentType.code,
      products,
      redirect_url: Config.PAYMENT_REDIRECT_URL,
      payment_gst_number: gstID,
    }
    if (notify.isStore) {
      if (selectedPaymentType.payment_client == 'rv') {
        totalList['voucher_number'] = receiptFields.invoice
        totalList['voucher_date'] = receiptFields.date
      }
      totalList['referer_id'] = totalEmpDetails[0].employee_id
      totalList['store_id'] = notify.storeName
      totalList['referer_name'] = totalEmpDetails[0].employee_name
    }
    if (!!selectedPaymentType.product_id) {
      totalList['offers'] = {
        product_id: selectedPaymentType.product_id,
        item_code: selectedPaymentType.item_code,
      }
    }

    return OrderService.confirmOrder(totalList)
      .then((res) => {
        if (selectedPaymentType.payment_client == 'razorpay') {
          setDbOrderID(res.data.data.order_id)
        }
        localStorage.setItem('orderID', res.data.data.order_id)
        return res
      })
      .catch((err) => {
        Logger.error(err)
        notify.appNotification(`${err.response.data.error.message}`, 'ERROR', {
          cancalable: true,
        })
      })
  }
  const submitReferer = async (val: any) => {
    setLoaderTxt(true)
    StoreService.getEmployeeReferer(val)
      .then((res) => {
        setLoaderTxt(false)
        if (res.data.length) {
          setTotalEmpDetails(res.data)
          setVerifyEmpReferer(true)
        } else if (res.data.error) {
          setLoaderTxt(true)
          setTotalEmpDetails([])
          setVerifyEmpReferer(false)
          notify.appNotification(`${res.data.error}`, 'ERROR')
        }
      })
      .catch((err) => {
        Logger.log(err)
      })
  }
  const allproductID = confirmOrderList.map((list, i) => {
    let datasellingprice = sellingPrice(list?.prices[0]?.sp)
    let datamrpprice = mrpPrice(list?.mrp[0]?.price)
    return {
      itemCode: list.item_code,
      itemSellingPrice: list.pre_order
        ? list.preorder_amount
        : datasellingprice?.length != 0
        ? datasellingprice[0]?.price
        : datamrpprice[0]?.price,
      preTaxItemPrice: 0,
      quantity: list.quantity,
    }
  })
  const submitCoupon = async (total: any) => {
    let channel = Cookies.get('store')
    CartService.validateCoupon({
      customerId: String(authState.state.id),
      customerMobile:
        authState.state.id && !!authState.state.firstName
          ? authState.state.mobileno
          : selected.mobilenumber,
      customerEmail:
        authState.state.id && !!authState.state.firstName
          ? authState.state.email
          : selected.email,
      couponCode: coupon,
      channel: !!channel ? Channel.SHOP : Channel.ONLINE,
      cartValue: total,
      items: confirmOrderList.map((el: any) => {
        let datasellingprice = sellingPrice(el?.prices[0]?.sp)
        let datamrpprice = mrpPrice(el?.mrp[0]?.price)
        return {
          itemId: el.item_code,
          itemSellingPrice: el.pre_order
            ? el.preorder_amount
            : datasellingprice.length != 0
            ? datasellingprice[0]?.price
            : datamrpprice[0]?.price,
          quantity: el.quantity,
        }
      }),
    })
      .then((res) => {
        if (!!res.data.data) {
          setCouponData(res.data.data)
        }
        if (!!res.data.error) {
          setCoupon('')
          setCouponData('')
          notify.appNotification(res.data.message, 'ERROR')
        }
      })
      .catch((err) => {
        setCoupon('')
        setCouponData('')
        notify.appNotification(`${err.response.data.message}`, 'ERROR')
      })
  }

  const [allCoupons, setAllCoupons] = useState([])
  const [couponModel, setCouponModel] = useState(false)

  const viewAllCoupons = async (total: any) => {
    let body = {
      customerId: String(authState.state.id),
      customerMobile:
        authState.state.firstName.toUpperCase() != 'GUEST'
          ? authState.state.mobileno
          : selected.mobilenumber,
      customerEmail:
        authState.state.firstName.toUpperCase() != 'GUEST'
          ? authState.state.email
          : selected.email,
      cartValue: total,
      products: allproductID,
    }
    return await CartService.couponsForCheckout(body)
      .then((res) => {
        if (!!res.data?.couponData) {
          setAllCoupons(res.data.couponData)
          setCouponModel(true)
        }
      })
      .catch((err) => {
        notify.appNotification(`${err.response.data.message}`, 'ERROR')
        setCouponModel(false)
        return
      })
  }
  const [confirmOrderLoader, setConfirmOrderLoader] = useState(true)
  const [confirmOrderDeliveryList, setConfirmOrderDeliveryList] = useState(
    [] as any
  )
  useState([] as any)
  const getConrifmOrderDelivery = async () => {
    setConfirmOrderLoader(true)
    let channel = Cookies.get('store')
    const orderResult = confirmOrderList.map(async (el: any) => {
      try {
        const res: any = await PimHomeService.PimDelivery({
          item_code: el.item_code,
          pincode: Number(selected.postcode),
          channel: !!channel ? Channel.SHOP : Channel.ONLINE,
        })
        el['canDeliver'] = !!res.data.data
        if (!!res.data.data.length) {
          el['deliveryOption'] = res.data.data[0]
          el['deliveryType'] = res.data.data
        } else {
          el['deliveryType'] = []
        }
        return el
      } catch (err) {
        Logger.error(err, 'DELIVERY')
        return el
      }
    })
    return await Promise.all(orderResult)
      .then((res) => res)
      .then((resp) => {
        Logger.info('orderResult', resp)
        setConfirmOrderDeliveryList(resp)
        setConfirmOrderLoader(false)
      })
      .catch(Logger.error)
  }
  const [
    confirmOrderAdressList,
    setConfirmOrderAddressList,
  ]: any = useState<any>({})
  const updateItemAddress = (slug: any, val: any) => {
    let payload: any = (pre: any) => {
      let newVal = [...pre].map((el: any) => {
        if (el.slug == slug) {
          el['deliveryOption'] = val
          el['canDeliver'] = !!val
        }
        return el
      })
      return newVal
    }
    setConfirmOrderList(payload)
  }

  useEffect(() => {
    notify.removeNotify()
  }, [selectedPaymentType])

  const removeDeliveryOption = () => {
    let newValue = [...confirmOrderList].map((e) => {
      let val = { ...e }
      delete val.canDeliver
      delete val.deliveryOption
      delete val.deliveryType
      return val
    })
    setConfirmOrderList(newValue)
  }

  return {
    removeDeliveryOption,
    confirmOrderAdressList,
    setConfirmOrderAddressList,
    confirmOrderLoader,
    confirmOrderDeliveryList,
    getConrifmOrderDelivery,
    dbOrderID,
    updateItemAddress,
    coupon,
    setCoupon,
    couponData,
    setCouponData,
    submitCoupon,
    selectedPaymentType,
    setSelectedPaymentType,
    confirmOrderList,
    setConfirmOrderList,
    moveToConfirm,
    productOffer,
    productPaymentOptions,
    getPaymentMethods,
    deleteAddress,
    gstDetails,
    setGstDetails,
    saveOrders,
    showGstDetails,
    setShowGstDetails,
    getGSTDetails,
    updateGstDetail,
    gstID,
    setGstID,
    updateQuantity,
    cartItemTotal,
    initialData,
    items,
    address,
    setAddress,
    updateAddressFields,
    updateReceiptFields,
    receiptFields,
    setReceiptFields,
    paymentMethodsLoader,
    addressList,
    selected,
    setSelected,
    emptyAddress,
    formType,
    setFormType,
    removeFromCart,
    addFromWhislist,
    addNewAddress,
    postalDetail,
    updateAddress,
    setPostalDetail,
    buyNow,
    gstAddress,
    setBillingSelected,
    billingSelected,
    emptyGSTDetails,
    loader,
    refreshConfirmOrder,
    setReferer,
    referer,
    submitReferer,
    totalEmpDetails,
    setVerifyEmpReferer,
    verifyEmpReferer,
    loaderTxt,
    confirmOrderListDupe,
    setNotifyMeModel,
    notifyMeModel,
    viewAllCoupons,
    allCoupons,
    setCouponModel,
    couponModel,
    // pricelist
  }
}

export const CartState: any = createContainer(Cart)
