import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import style from './app-bar.module.scss'
import { FormInput } from '../forms/form-input/form-input'
import { StoreService } from '~/services/stores.service'
import { Logger } from '~/util'

export default function Commonlocator({ setIsShown }: any) {
  const router = useRouter()

  const [countrystate, setCountrystate] = useState([])
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [selectedcity, setSelectedcity] = useState([])
  const [selectedarea, setSelectedarea] = useState([])
  const [loading, setLoading] = useState(false)
  const [pincode, setPincode] = useState('')

  useEffect(() => {
    commonstate()
  }, [])
  const commonstate = async () => {
    setLoading(true)
    await StoreService.stateData()
      .then(async (response) => {
        const results = await response.json()
        setCountrystate(results.data)
      })
      .catch((err) => {
        Logger.error(err)
      })
      .finally(() => setLoading(false))
  }
  const handleStateChange = async (e: any) => {
    const a = e.target.value
    setState(a)
    setLoading(true)
    await StoreService.cityData(a)
      .then(async (response) => {
        const result = await response.json()
        setSelectedcity(result.data)
      })
      .catch((err) => {
        Logger.error(err)
      })
      .finally(() => setLoading(false))
  }
  const handleCityChange = async (e: any) => {
    const c = e.target.value
    setCity(c)
    setLoading(true)
    await StoreService.areaData(state, c)
      .then(async (response) => {
        const results = await response.json()
        setSelectedarea(results.data)
      })
      .catch((err) => {
        Logger.error(err)
      })
      .finally(() => setLoading(false))
  }
  const handleAreaChange = (e: any) => {
    const areadetail = e.target.value
    setIsShown(false)
    router.push({
      pathname: '/showrooms',
      query: { areadetail, city, state },
    })
  }

  return (
    <div>
      <div className={`${style.common_locator}`}>
        <ul
          className={`${style.dropdown_locator} flex justify-between mb-1 pl-8 pr-8 pt-4 `}
        >
          <li>
            <Link
              href={{
                pathname: '/showrooms',
              }}
              passHref
              onClick={() => setIsShown(false)}
              className={`${style.poorvika_store} ${
                router.asPath.includes('/showrooms')
                  ? style.active_color
                  : style.inactive_color
              }`}
            >
              Poorvika Stores
            </Link>
          </li>
          <Link
            href={{
              pathname: '/service-center',
            }}
            passHref
          >
            <li
              onClick={() => setIsShown(false)}
              className={`${style.service_center} ${
                router.asPath == '/service-center'
                  ? style.active_color
                  : style.inactive_color
              }`}
            >
              Service Centre
            </li>
          </Link>
        </ul>
        <ul className={`${style.dropdown_menu} pt-3 w-9/12 mx-auto`}>
          <li>
            <span
              className={`${style.locator_input} border-b-2 mb-4 flex justify-between items-baseline`}
            >
              <FormInput
                placeholder="Enter Pincode"
                value={`${/^\d+$/.test(pincode) ? pincode : ''}`}
                onChange={(val) => {
                  setPincode(val.replace(/\D/g, ''))
                  setState('')
                  setCity('')
                }}
                id="locator_header"
                maxLength={6}
                autoComplete={`off`}
                inputMode="numeric"
              />
              <div
                className={`${
                  pincode.length == 6 ? '' : 'select-none pointer-events-none'
                }`}
              >
                <Link
                  href={{
                    pathname: '/showrooms',
                    query: { pincode },
                  }}
                  passHref
                >
                  <div
                    className={`${style.pincode_check}`}
                    onClick={() => setIsShown(false)}
                  >
                    Check
                  </div>
                </Link>
              </div>
            </span>
          </li>
          <li className="text-center text-sm mb-3 text-gray-500 font-extrabold">
            OR
          </li>
          <li className={`${style.locator_select}`}>
            <select
              className={`${style.dropdown_select}`}
              onChange={(e) => {
                handleStateChange(e), setPincode('')
              }}
              disabled={loading}
              value={state}
            >
              <option>Select State</option>
              {countrystate.map((e: any, k: number) => (
                <option key={k} value={e.state_code}>
                  {e.state}
                </option>
              ))}
            </select>
          </li>
          <li className={`${style.locator_select}`}>
            <select
              className={`${style.dropdown_select}`}
              onChange={(e) => handleCityChange(e)}
              value={city}
              disabled={loading}
            >
              <option>Select City</option>
              {selectedcity.map((e: any, c: number) => (
                <option key={c} value={e.district_code}>
                  {e.district}
                </option>
              ))}
            </select>
          </li>
          <li className={`${style.locator_select}`}>
            <select
              className={`${style.dropdown_select}`}
              onChange={(e) => {
                handleAreaChange(e)
              }}
              disabled={loading}
            >
              <option>Select Area</option>
              {selectedarea.map((post: any, a: number) => (
                <option key={a} value={post.area_code}>
                  {post.area_name}
                </option>
              ))}
            </select>
          </li>
        </ul>
      </div>
    </div>
  )
}
