import dynamic from 'next/dynamic'
import Link from 'next/link'
import Cookies from 'js-cookie'
import { NotifyEle } from '../../notification/notify'
import { UseNotificationState } from '~/stores/notify'

const Img: any = dynamic(() => import('next/image'))
/**
 * app bar logo contains basic logo image
 * @returns {JSX.Element} returns app bar contains basic logo image
 */
export const AppBarLogo = () => {
  const notify = UseNotificationState.useContainer()

  const src = 'https://img.poorvika.com/common/Poorvika-english-logo.svg'
  return (
    <Link href={'/'}>
      <img
        //className={`${style.desk_single_logo}`}
        // style={{ width: '126px' }}
        src={src}
        alt="poorvika"
        width="175"
        height="35"
        // loader={() => src}
        // blurDataURL={src}
        //placeholder="blur"
      />
    </Link>
  )
}
