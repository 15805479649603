import { Lang } from '~/translations/LangKeys'

export const FooterText: any = {
  ContactUs: {
    [Lang.ENGLISH]: 'Contact Us',
    [Lang.TAMIL]: 'எங்களை தொடர்பு கொள்ள',
  },
  Support: {
    [Lang.ENGLISH]: 'Support',
    [Lang.TAMIL]: 'ஆதரவு',
  },
  SiteMap: {
    [Lang.ENGLISH]: 'Site Map',
    [Lang.TAMIL]: 'தள வரைபடம்',
  },
  OneAssist: {
    [Lang.ENGLISH]: 'One Assist',
    [Lang.TAMIL]: 'ஒரு உதவி',
  },
  TAndC: {
    [Lang.ENGLISH]: 'T & C',
    [Lang.TAMIL]: 'விதிமுறைகள் மற்றும் நிபந்தனைகளின்',
  },
  PrivacyPolicy: {
    [Lang.ENGLISH]: 'Privacy Policy',
    [Lang.TAMIL]: 'தனியுரிமைக் கொள்கை',
  },
  CSRPolicy: {
    [Lang.ENGLISH]: 'CSR & Whistle Blower Policy',
    [Lang.TAMIL]: 'CSR & விசில் ப்ளோவர் பாலிசி',
  },
  Policies: {
    [Lang.ENGLISH]: 'Policies',
    [Lang.TAMIL]: 'கொள்கைகள்',
  },
  AboutUs: {
    [Lang.ENGLISH]: 'Return, Replacement & Refund',
    [Lang.TAMIL]: 'எங்களை பற்றி',
  },
  Shop: {
    [Lang.ENGLISH]: 'Know More',
    [Lang.TAMIL]: 'கடை',
  },
  Opportunities: {
    [Lang.ENGLISH]: 'Opportunities',
    [Lang.TAMIL]: 'வாய்ப்புகள்',
  },
  BeAnAffiliate: {
    [Lang.ENGLISH]: 'Careers',
    [Lang.TAMIL]: 'தொழில்',
  },
  BeAnInfluencer: {
    [Lang.ENGLISH]: 'Be an Influencer',
    [Lang.TAMIL]: 'ஒரு செல்வாக்கு செலுத்துபவராக இருங்கள்',
  },
  CreateContent: {
    [Lang.ENGLISH]: 'Create Content',
    [Lang.TAMIL]: 'தொடர்பை உருவாக்கவும்',
  },
  Aboutus: {
    [Lang.ENGLISH]: 'About Us',
    [Lang.TAMIL]: 'கைபேசி',
  },
  OurStores: {
    [Lang.ENGLISH]: 'Our Stores',
    [Lang.TAMIL]: 'எங்கள் கடைகள்',
  },
  Servicecentres: {
    [Lang.ENGLISH]: 'Service Centres',
    [Lang.TAMIL]: 'சேவை மையங்கள்',
  },
  AboutUsText: {
    [Lang.TAMIL]:
      'எங்களைப் பற்றி: பூர்விகா ஆசியாவில் ஸ்மார்ட்போன்கள், டேப்லெட்டுகள் மற்றும் ஆபரணங்களுக்கான மிகப்பெரிய ஆம்னி சேனல் சில்லறை விற்பனையாளர். இந்நிறுவனம் தமிழ்நாடு, கர்நாடகா, மகாராஷ்டிரா, கேரளா மற்றும் பாண்டிச்சேரி ஆகிய நாடுகளில் 460+ க்கும் மேற்பட்ட ஒன்-ஸ்டாப் மொபைல் கடைகளை அமைத்துள்ளது, அங்கு வாடிக்கையாளர்கள் தங்களுக்கு விருப்பமான ஸ்மார்ட்போன்களை எளிதாக வாங்க முடியும்.',
    [Lang.ENGLISH]: ` Poorvika is the Largest Tech Retailer in India, spanning across 460+ showrooms in and around Tamil Nadu, Karnataka, Pondicherry, Mumbai, Pune and Trivandrum, famous for their touch & feel experience. Poorvika sells a wide category of devices in its showrooms and Online portal, ranging from the Best Smartphones, Laptops, Computers, Smart Devices, Smart TVs to Accessories. Poorvika's E-Commerce platform www.poorvika.com caters to customers across India where Customers can Comfortably Order their devices with just a tap and get them delivered Safely with delivery options such as 2 Hours Delivery, Same Day Delivery, Next Day Delivery, and a Pickup at the Store option based on their location. Having served over 5 Crore+ Happy Customers, Poorvika takes pride in being India's leading retailer for Top Brands like Apple, Samsung, Oppo, Vivo, Xiaomi, OnePlus, Redmi, Realme, Nokia, etc.`,
  },
  ContactInformation: {
    [Lang.ENGLISH]: 'Contact Information',
    [Lang.TAMIL]: 'தொடர்பு தகவல்',
  },
  HeadOffice: {
    [Lang.ENGLISH]: 'Head Office',
    [Lang.TAMIL]: 'தலைமை அலுவலகம்',
  },
  HeadOfficeDetail: {
    [Lang.ENGLISH]: 'Poorvika Mobiles Pvt Ltd ',
    [Lang.TAMIL]: 'பூர்விகா மொபைல்ஸ் பிரைவேட் லிமிடெட்.',
  },
  HeadOfficeAddress: {
    [Lang.ENGLISH]:
      'Admin Office No.30, Arcot Road, Kodambakkam, Chennai- 600 024.',
    [Lang.TAMIL]:
      'நிர்வாக அலுவலகம் எண் 30, ஆர்காட் சாலை, கோடம்பாக்கம், சென்னை- 600 024.',
  },
}
